<template>
    <div class="test">
        <div class="test__left">
            <img src="@/assets/newImages/test.png"/>
        </div>
        <div class="test__right">
            <p class="test__right__title">Записаться на тест-драйв</p>
            <div class="test__right__inputs">
                <div class="test__right__inputs__input">
                    <input
                    type="text"
                    :placeholder="$t('call_4')"
                    v-model="testData.name"/>
                    <span class="error-message">{{ fieldErrors.name }}</span>
                </div>
                <div class="test__right__inputs__input">
                    <input v-model="testData.phone"
                        v-mask="'+7 (###) ###-##-##'"
                        id="phoneInput"
                        :placeholder="$t('call_3')"
                        type="text"
                        @input="isNumber"/>
                        <span class="error-message">{{ fieldErrors.phone }}</span>
                </div>
                <div class="test__right__inputs__input" >
                    <select v-model="testData.city" id="citySelect">
                        <option value="" disabled selected>{{ $t('td_1') }}</option>
                        <option v-for="(item, idx) in cities" :key="'c' + idx" :value="item">
                            {{ item.name }}
                        </option>
                        </select>
                    <span class="error-message">{{ fieldErrors.city }}</span>
                </div>
                <div class="test__right__inputs__input">
                    <select v-model="testData.dealer" id="dealerSelect">
                    <option value="" disabled selected>Дилер:</option>
                    <option v-for="(item, idx) in newDealers" :key="'d' + idx" :value="item">
                        {{ item.name }}
                    </option>
                    </select>
                    <span class="error-message">{{ fieldErrors.dealer }}</span>
                </div>
            </div>
            <div class="test__right__check">
                <div class="test__right__check__top" >
                    <input type="checkbox" v-model="testData.agreement"/>
                    {{ $t('td_2') }}<br />{{ $t('td_3') }}
                </div>
                <span class="error-message">{{ fieldErrors.agreement }}</span>
            </div>
            <button id="submitButton" @click="sendData" :disabled="isLoading">
                    <div class="loader" v-if="isLoading">
                        <span class="bar"></span>
                        <span class="bar"></span>
                        <span class="bar"></span>
                    </div>
          <div v-else>{{ $t('td_4') }}</div>
    </button>
        </div>
    </div>  
</template><script>
import { mask } from "vue-the-mask";
import { ref } from "vue";
import axios from "axios";
import { useHead } from "@vueuse/head"
import { useVuelidate } from '@vuelidate/core'
import { toast } from 'vue3-toastify';
import { required, minLength, helpers, email, sameAs  } from "@vuelidate/validators";
import 'vue3-toastify/dist/index.css';
export default {
  setup() {
        const operators = ref([700, 701, 702, 705, 707, 708, 747, 771, 775, 776, 777, 778]);
        const isNumber = (e) => {
            let regex = /[0-9]/;
            if (!regex.test(e.key)) {
                e.preventDefault();
            }
            const fullNumber = e.target.value;
            const formattedNumber = fullNumber.replace(/[^0-9]/g, ''); // Удалить нецифровые символы
            if (formattedNumber === '7777777777') {
                e.target.value = "";
                toast.error('Такого сотового оператора не существует')
            }
            if (formattedNumber.length >= 4) { // Проверяем длину номера
                let operatorCode = parseInt(formattedNumber.slice(1, 4)); // Берем первые 3 цифры
                if (!operators.value.includes(operatorCode)) {
                    e.target.value = "";
                    toast.error('Такого сотового оператора не существует')
                }
            }
        };
        return { 
            v$: useVuelidate(),
            isNumber,
            operators
        };
    },
  props: {
    modalTitle: String, // Пропс для заголовка модального окна
  },
  directives: { mask },
  data: () => ({
    isLoading: false,
    newDealers: false,
    currentPath: "",
    model: 1,
    newContactId: "",
    cities: '',
    testData: {
      name: null,
      dealer: "",
      city: "",
      phone: "",
      agreement: false,
    },
    requiredFields: ["name", "dealer", "city", "phone", "agreement"], // список обязательных полей
    fieldErrors: {},
    city: [
      {
        id: 1,
        name: "Алматы",
      },
      {
        id: 2,
        name: "Астана",
      },
    ],
  }),
  methods: {
    async getPage() {    
            await this.$axios.get(`dealers`)
            .then(res => {
                this.newDealers = res.data.dealers
            })
            .catch(err => {
            })     
            
            
            await this.$axios.get(`https://admin.hongqi-auto.kz/api/V1/cities?lang=kz`)
            .then(res => {
                this.cities = res.data.cities
            })
            .catch(err => {
            })
        },
    sendData() {
    this.fieldErrors = {};
    let hasErrors = false;
    for (const field of this.requiredFields) {
      if (!this.testData[field]) {
        this.fieldErrors[field] = "Обязательное поле";
        hasErrors = true;
      }
    }
    if (hasErrors) {
      this.isLoading = false; // Установка isLoading в false, если есть ошибки
      return;
    }
    this.isLoading = true;
    const formattedPhone = this.testData.phone.replace(/\+7|\(|\)|-|\s/g, '');
      const back = {
        name: this.testData?.name,
        phone: formattedPhone,
        dealer: this.testData?.dealer?.bitrix_id,
        model: 'undefined:)',
        type: 'model'
      };
      axios
                    .post(
                      "https://admin.hongqi-auto.kz/api/V1/send-application",
                      back
                    )
                    .then((response) => {
                      if (response.status === 200) {
                        this.$store.state.openOk = true;
                        this.isLoading = false;
                      } else {
                        console.log(response);
                      }
                    })
                    .catch((e) => {
                      console.log(e);
                    })
                    .finally(() => {
                    this.testData.name = '';
                    this.testData.dealer = '';
                    this.testData.phone = '';
                    this.testData.city = '';
                    this.testData.agreement = false;
                  });
    },
  },
  async created() {
    this.getPage()
  },
};
</script>
<style lang="scss" scoped>
.loader {
  display: flex;
  align-items: center;
  justify-content: center;
}


.bar {
  display: inline-block;
  width: 3px;
  height: 10px;
  background-color: rgba(255, 255, 255, .5);
  border-radius: 10px;
  animation: scale-up4 1s linear infinite;
}

.bar:nth-child(2) {
  height: 20px;
  margin: 0 5px;
  animation-delay: .25s;
}

.bar:nth-child(3) {
  animation-delay: .5s;
}

@keyframes scale-up4 {
  20% {
    background-color: #ffff;
    transform: scaleY(1.5);
  }

  40% {
    transform: scaleY(1);
  }
}
.error-message {
  color: red;
  font-size: 12px;
  margin-top: 5px;
}
    .test{
        display: flex;
        justify-content: space-between;
        &__left{
            width: 50%;
            img{
                width: 100%;
                aspect-ratio: 1.136;
                object-fit: cover;
                object-position: left;
            }
        }
        &__right{
            width: 50%;
            display: flex;
            flex-direction: column;
            gap: min(max(25px, calc(1.5625rem + ((1vw - 7.68px) * 2.1701))), 50px);
            justify-content: center;
            align-items: center;
            padding: 0px min(max(40px, calc(2.5rem + ((1vw - 7.68px) * 3.0382))), 75px);
            background-color: #F3F3F3;
            &__title{
                font-size: min(max(24px, calc(1.5rem + ((1vw - 7.68px) * 1.3889))), 40px);
            }
            &__inputs{
                width: 100%;
                display: flex;
                flex-wrap: wrap;
                gap: 10%;
                row-gap: 30px;
                &__input{
                    width: 45%;
                    input, select{
                        padding: min(max(12px, calc(0.75rem + ((1vw - 7.68px) * 0.6944))), 20px);
                        cursor: pointer;
                        background-color: inherit;
                        border: none;
                        width: 100%;
                        outline: none;
                        border-bottom: 1px solid #000000;
                    }
                }
            }
            &__check{
                display: flex;
                flex-direction: column;
                &__top{
                    display: flex;
                    gap: 25px;
                    align-items: flex-start;
                    color: #6D6E6F;
                    font-size: min(max(10px, calc(0.625rem + ((1vw - 7.68px) * 0.3472))), 14px);
                    input{
                        width: 30px;
                        height: 30px;
                        cursor: pointer;
                    }
                }
            }
            button{
                width: 40%;
                padding: 15px 0px;
                border-radius: 30px;
                color: white;
                border: none;
                background-color: #42373C;
                cursor: pointer;
            }
        }
    }
</style>
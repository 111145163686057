<template>
  <div class="home">
    <div class="home__block1" v-if="home">
        <swiper 
         class="home__block1__swiper desctop"
        :slidesPerView="1" 
        :pagination="{ 
          clickable: true, 
          type: 'bullets', 
          horizontalClass: 'bullets-content', 
          bulletClass: 'bulletsA', 
          bulletActiveClass: 'bulletsA-active', 
          el: '.paginationclass',
        }"
        :autoplay="{
          delay: 3000
        }"
        :lazy="true"
        :loop="true"
        :navigation="{ 
          prevEl: '.arrow1', 
          nextEl: '.arrow2', 
          disabledClass: 'disabled' 
        }"
        > 
        <swiper-slide v-for="(item) in home.sliders" :key="item.id" class="home__block1__swiper__slider"> 
          <a :href="item.link">
            <div class="home__block1__cards" :style="{ backgroundImage: 'url(' + item.image + ')' }">
              <div class="home__block1__cards__arrows">
                <img src="@/assets/icons/prev.svg" class="arrow1"/>
                <img src="@/assets/icons/next.svg" class="arrow2"/>
              </div>
            </div>
          </a>
        </swiper-slide> 
        </swiper>
      <div class="paginationclass desctop">
      </div>
    <div class="mobile">
      <swiper 
        :slidesPerView="1" 
        :pagination="{ 
                      clickable: true, 
        }"
        :lazy="true" 
        :loop="true"
        :navigation="{ 
                        prevEl: '.arrow1', 
                        nextEl: '.arrow2', 
                        disabledClass: 'disabled' 
                    }"
      > 
        <swiper-slide v-for="(item) in home.sliders" :key="item" > 
          <a :href="item.link"><div class="mobilehome__block1__cards" :style="{ backgroundImage: 'url(' + item.image_mob + ')'}">
          <div class="mobilehome__block1__cards__arrows">
          </div>
          </div></a>
        </swiper-slide> 
      </swiper>
    </div>
    </div>
    <div class="home__block2" v-if="home">
      <div class="home__block2__title">
        <p> {{ $t('home_1') }}</p>
      </div>
      <div class="home__block2__names">
        <div v-for="section in home.types" :key="section.title" @click="selectSection(section.title)" :class="{ active: section.title === selectedSection }">
          {{ section.title }}
          <div class="triangle" v-show="section.title === selectedSection"></div>
        </div>
      </div>
      <div class="home__block2__cars">
        <div v-for="car in filteredCars" :key="car.title" class ="home__block2__cars__in" >
          <div class="home__block2__cars__in__image" @click="selectCar(car.title)" :class="{ active: car.title === selectedCar }">
            <v-lazy-image :src="car.logo"/>
          </div>
          <div @click="selectCar(car.title)" :class="{ active: car.title === selectedCar }">
          {{ car.title }}
          <div class="active-line" v-show="car.title === selectedCar"></div>
        </div>
        </div>
      </div>
    </div>
      <swiper
      @slideChange="onSlideChange"
      :slidesPerView="1" 
        :navigation="{ 
                        prevEl: '.arrow1', 
                        nextEl: '.arrow2', 
                        disabledClass: 'disabled' 
                    }"
          @swiper="onSwiper"
      >
      <swiper-slide v-for="car in allCars" :key="car.id">
        <div class="home__blockcar">
          <router-link :to="'/models/'+car.slug" style="text-decoration: none; color: inherit;"><div class="home__blockcar__top">
          <div class="home__blockcar__top__left">
            <p class="home__blockcar__top__left__title">{{car.title}}</p>
            <router-link to="/test">
            <button class="home__blockcar__top__left__button" style="cursor: pointer;">
              ТЕСТ-ДРАЙВ
            </button>
          </router-link>
          </div>
          <div class="home__blockcar__top__right" >
            <!-- <div v-if="seletedCarActive == color.id"> -->
              <img :src="selectedCarImg"/>
          <!-- </div> -->
          </div>
        </div>
        </router-link>
        <div class="home__blockcar__bot">
          <div class="home__blockcar__bot__box" v-for="color in car.colors" :key="color">
            <v-lazy-image
                class="color-img"
                :src="color.hex"
                :data-color="color.title"
                :data-carimg="color.image"
                @click="selectColor(color)"
              />
          </div>
        </div>
      </div>
      </swiper-slide>
    </swiper>
    <!-- <div class="home__block3background">
      <div class="home__block3"  v-if="home">
        <div class="home__block3__title">
          <p>{{ $t('home_2') }}</p>
        </div>
        <div class="home__block3__cards">
          <div class="home__block3__cards__top">
            <div class="home__block3__cards__top__1" :style="'background-image: url(' + home.world[0].image + ');'"  @click="$router.push('/hisory')">
              <p class="home__block3__cards__top__1__title">{{home.world[0].title}}</p>
              <button class="home__block3__cards__top__1__button">
                <router-link to="/history" style="text-decoration: none; color: inherit;"><p>{{ $t('home_3') }}</p></router-link>
              </button>
            </div>
            <div class="home__block3__cards__top__2" :style="'background-image: url(' + home.world[1].image + ');'" @click="$router.push('/brand')">
              <p class="home__block3__cards__top__2__title">{{home.world[1].title}}</p>
              <button class="home__block3__cards__top__2__button"><p>{{ $t('home_3') }}</p>
              </button>
            </div>
          </div>
          <div class="home__block3__cards__bot">
            <div class="home__block3__cards__bot__1" :style="'background-image: url(' + home.world[2].image + ');'"  @click="$router.push('/develop')">
              <p class="home__block3__cards__bot__1__title">
                {{home.world[2].title}}
              </p>
              <button class="home__block3__cards__bot__1__button"><router-link to="/develop" style="text-decoration: none; color: inherit;"><p>{{ $t('home_3') }}</p></router-link></button>
            </div>
            <div class="home__block3__cards__bot__2" :style="'background-image: url(' + home.world[3].image + ');'"  @click="$router.push('/achievement')">
              <p class="home__block3__cards__bot__2__title">
                {{home.world[3].title}}
              </p>
              <button class="home__block3__cards__bot__2__button"><router-link to="/achievement" style="text-decoration: none; color: inherit;"><p>{{ $t('home_3') }}</p></router-link></button>
            </div>
            <div class="home__block3__cards__bot__3" :style="'background-image: url(' + home.world[4].image + ');'" @click="$router.push('/social')">
              <p class="home__block3__cards__bot__3__title">
                {{home.world[4].title}}
              </p>
              <button class="home__block3__cards__bot__3__button"><router-link to="/social" style="text-decoration: none; color: inherit;"><p>{{ $t('home_3') }}</p></router-link></button>
            </div>
          </div>
        </div>
      </div>
    </div> -->
      <div class="home__block4">
        <div class="home__block4__title">
          <p>{{ $t('home_4') }}</p>
        </div>
        <div class="home__block4__cards" v-if="news">
        <div class="home__block4__cards__card" v-for="item in news.articles" :key="item">
              <div class="home__block4__cards__card__image"  @click="$router.push('/news/' + item.slug)"> 
                <v-lazy-image :src="item.image"/>
              </div>
              <div class="home__block4__cards__card__title">
                {{ item.title }}
              </div>
              <div class="lineblock4"></div>
              <div class="home__block4__cards__card__bot">
                <div class="home__block4__cards__card__bot__date">
                </div>
                <div class="home__block4__cards__card__bot__button"><button @click="$router.push('/news/' + item.slug)">{{ $t('home_3') }}</button>
                </div>
              </div>
            </div>
        </div>
      </div>
  </div>
</template>

<script>
import { Swiper, SwiperSlide } from "swiper/vue"; 
import "swiper/css"; 
import "swiper/css/pagination"; 
import "swiper/css/navigation";  
import "swiper/swiper.min.css";
import VLazyImage from "v-lazy-image";
import SwiperCore, { A11y, Autoplay } from "swiper"; 
import { Navigation, Pagination } from 'swiper'; 
import "swiper/swiper.min.css"; 
import { useHead } from "@vueuse/head"
SwiperCore.use([Navigation, Pagination, A11y, Autoplay]);
export default {
  setup() {
        useHead({
            title: "Hongqi Qazaqstan (Хончи Казахстан) - официальный дистрибьютор премиум автомобилей. Рассрочка на 5 лет. Гарантия 7 лет или 200 000 км. В подарок 5 ТО. Бесплатный тест-драйв. Модельный ряд: H5, HS5, H9 и E-HS9.",
            meta: [
                { hid: 'description', name: 'description', content: 'Описание страницы' },
                { hid: 'canonical', rel: 'canonical', href: 'https://hongqi-auto.kz'},
            ],
        })
    },
    data() {
    return {
      colored: "",
      home: null,
      imaged: '',
      selectedColor: "blackh9",
      selectedCarImg: "",
      seletedCarActive: 111,
      swiperIndex: 0,
      selectedSection: 'Sedan',
      selectedCar: 'H5',
      allCars: [],
      news:[]
    }
  },
  async created() {
    await this.$axios.get(`/home?lang=ru`)
    .then(res => {
      this.home = res.data      
      this.home.types.forEach(section => {
        this.allCars.push(...section.models);
      });
    })
    await this.$axios.get(`news`)
    .then(res => {
      this.news = res.data
    })
    this.selectedCarImg = this.allCars[0].colors[0].image
  },
  methods: {
    onSwiper(swiper) {
      this.swiper = swiper;
      this.swiperIndex = swiper.activeIndex;
    },
    selectSection(sectionName) {
      this.selectedSection = sectionName;
    },
    selectCar(value) {
      this.selectedCar = value;
    },
    onSwiper(swiper) {
      this.swiper = swiper;
    },
    selectColor(color) {
      this.selectedCarImg = color.image;
    },
    onSlideChange() {
      const activeSlideIndex = this.swiper.activeIndex;
      this.selectedCarImg = this.allCars[this.swiper.activeIndex].colors[0].image
    },
  },
  components: {
    Swiper,
    SwiperSlide,
    VLazyImage
  },
  computed: {
    selectedCarIndex() {
      return this.allCars.findIndex((car) => car.title === this.selectedCar);
    },
    filteredCars() {
      let activeSection = []
      if (this.home) {
        activeSection = this.home.types.find(value => value.title === this.selectedSection).models;
      }
      return activeSection;
    }
  },
  watch: {
    selectedCarIndex(newIndex) {
      this.swiper.slideTo(newIndex);
    },
  },
}
</script>
<style lang="scss" scoped>
.arrow1{
  width: 50px;
  height: 50px;
}
.arrow2{
  width: 50px;
  height: 50px;
}
.mobilehome__block1__cards{
  height: min(max(350px, calc(21.875rem + ((1vw - 2.5px) * 145.0677))), 1100px);
  width: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}
.active {
  position: relative;
  font-weight: 800;
  width: fit-content;
}
.home__block3background{
  background-color: #f3f3f3;
}
.active .active-line {
  /* Стили для линии активной машины */
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: #000;
}

.triangle {
  /* Стили для маленького перевернутого треугольника */
  position: absolute;
  bottom: -10px;
  left: 50%;
  transform: translateX(-50%);
  width: 0;
  height: 0;
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  border-top: 7px solid black;
}
.color-img{
  border: 3px solid grey;
}
.lineblock4{
  border: 1px solid black;
}
.paginationclass{
  display: flex;
  gap: 10px;
  justify-content: center;
}
    .home{
      &__blockcar{
        display: flex;
        flex-direction: column;
        background-image: url('@/assets/newImages/backphone.webp');
        background-size: contain;
        min-height: 60vh;
        background-repeat: no-repeat;
        @media (max-width: 867px)
            {
              background-image: url('@/assets/newImages/backphonemobile.webp');
              background-size: 100% min(max(250px, calc(15.625rem + ((1vw - 2.5px) * 25.8398))), 450px);
              object-fit: cover;
            }
        &__top{
          display: flex;
          margin-left: min(max(48px, calc(3rem + ((1vw - 7.68px) * 2.7778))), 80px);
          box-sizing: border-box;
          justify-content: space-evenly;
          margin-top: min(max(40px, calc(2.5rem + ((1vw - 7.68px) * 1.7361))), 60px);
          @media (max-width: 867px){
            margin-top: 0px;
            gap: 0px;
            flex-direction: column;
            margin-left: 0px;
            align-items: center;
          }
          &__left{
            display: flex;
            flex-direction: column;
            margin-top: min(max(30px, calc(1.875rem + ((1vw - 7.68px) * 1.7361))), 50px);
            width: 40%;
            gap: 20px;
            @media (max-width: 867px)
            {
              gap: 20px;
              margin-top: 0px;
              margin-left: auto;
              margin-right: auto;
              align-items: center;
              width: 100%;
            }
            &__title{
              color: #282828;
              font-size: min(max(40px, calc(2.5rem + ((1vw - 10.24px) * 5.4688))), 89px);;
              font-family: -apple-system,system-ui,Segoe UI,Roboto,Ubuntu,Cantarell,Noto Sans,sans-serif,STHeiti,Microsoft YaHei,Arial;
              font-style: normal;
              font-weight: 700;
              line-height: normal;
              text-transform: uppercase;
              @media (max-width: 867px)
            {
              font-size: 60px;
            }
            }
            &__button{
              height: min(max(35px, calc(2.1875rem + ((1vw - 10.24px) * 5.0223))), 80px);;
              background: #333;
              border: none;
              color: #FFF;
              font-size: min(max(12px, calc(0.75rem + ((1vw - 7.68px) * 0.434))), 17px);
              font-family: -apple-system,system-ui,Segoe UI,Roboto,Ubuntu,Cantarell,Noto Sans,sans-serif,STHeiti,Microsoft YaHei,Arial;
              font-style: normal;
              font-weight: 400;
              line-height: normal;
              text-transform: uppercase;
              width: 50%;
              @media (max-width:867px){
                padding: 0.6em 1.5em;
                font-size: 3.2vw;
                height: 40px;
                font-size: 12px;
                width: 100%;
              }
            }
          }
          &__right{
            width: 70%;
            aspect-ratio: 2.61;
            margin-top: min(max(40px, calc(2.5rem + ((1vw - 7.68px) * 1.7361))), 60px);
            img{
              width: 100%;
              height: 100%;
              object-fit: cover;
        @media (max-width: 867px)
            {
              height:min(max(100px, calc(6.25rem + ((1vw - 2.5px) * 6.4599))), 150px);
              width: 100%;
              margin-top: 30px !important;
              object-fit: contain;
            }
            }
          }
        }
        &__bot{
          display: flex;
          gap: 13px;
          margin-left: min(max(48px, calc(3rem + ((1vw - 7.68px) * 2.7778))), 80px);
          padding-bottom: min(max(80px, calc(5rem + ((1vw - 7.68px) * 3.4722))), 120px);
          @media(max-width:867px){
              margin-left: 40px;
              margin-right: auto;
              margin-top: 20px;
              padding-bottom: 50px;
          }
          &__box{
            img{
              width: min(max(48px, calc(3rem + ((1vw - 10.24px) * 3.5714))), 80px);
              height: min(max(48px, calc(3rem + ((1vw - 10.24px) * 3.5714))), 80px);
              cursor: pointer;
              @media(max-width:867px){
                height: 40px;
                width: 40px;
              }
            }
          }
        }
       
      }
      &__block1{
        width: 100%;
        aspect-ratio: 2.68;
        &__swiper{
          width: 100%;
          height: 100%;
          &__slider{
            width: 100%;
            height: 100%;
          }
        }
        &__cards{
          position: relative;
          width: 100%;
          height: 100%;
          background-size: cover;
          background-repeat: no-repeat;
          background-position: center;
          &__arrows{
            position: absolute;
            padding: 0px 30px;
            top: 50%;
            display: flex;
            justify-content: space-between;
            width: 100%;
          }
        }
      }
      &__block2{
        margin-top: 40px;
        &__title{
          color: #282828;
          font-weight: 700;
          font-size: min(max(25px, calc(1.5625rem + ((1vw - 7.68px) * 0.6944))), 33px);
          text-transform: uppercase;
          text-align: center;
          @media(max-width:867px){
            font-size: 20px;
            margin-top: 20px;
          }
        }
        &__names{
          display: flex;
          gap: min(max(60px, calc(3.75rem + ((1vw - 7.68px) * 5.2083))), 120px);
          color: #282828;
          font-size: min(max(12px, calc(0.75rem + ((1vw - 7.68px) * 0.5208))), 18px);
          font-weight: 500;
          text-transform: uppercase;
          justify-content: center;
          padding-top: 60px;
          cursor: pointer;
          @media (max-width: 867px)
            {
              gap: 20px;
            }
        }
        &__cars{
          display: flex;
          gap: min(max(120px, calc(7.5rem + ((1vw - 7.68px) * 6.9444))), 200px);
          padding-top: min(max(30px, calc(1.875rem + ((1vw - 7.68px) * 2.6042))), 60px);
          padding-bottom: min(max(30px, calc(1.875rem + ((1vw - 7.68px) * 2.6042))), 60px);
          justify-content: center;
          @media(max-width:768px){
            gap: 0px;
            align-items: center;
          }
          &__in{
            width: 30%;
            cursor: pointer;
            display: flex;
            flex-direction: column;
            align-items: center;
            &__image{
              width: 100%;
              aspect-ratio: 1.955;
              img{
                width: 100%;
                height: 100%;
                object-fit: cover;
                display: flex;
              }
            }
            @media(max-width:768px){
              display: flex;
              flex-direction: column;
              align-items: center;
              width: 45%;
              p{
                font-size: 18px;
                color: #282828;
                font-weight: 700;
              }
            }
          }
        }
      }
      &__block3{
        display: flex;
        flex-direction: column;
        gap: min(max(60px, calc(3.75rem + ((1vw - 10.24px) * 3.9063))), 95px);
        padding-bottom: min(max(75px, calc(4.6875rem + ((1vw - 10.24px) * 4.4643))), 115px);
        @media(max-width:867px){
            gap: 20px;
            padding-bottom: 50px;
        }
        &__title{
          color: #282828;
          font-size: min(max(21px, calc(1.3125rem + ((1vw - 10.24px) * 1.3393))), 33px);
          font-weight: 700;
          text-align: center;
          padding-top: min(max(30px, calc(1.875rem + ((1vw - 7.68px) * 1.7361))), 50px);
          text-transform: uppercase;
          @media(max-width:867px){
            padding-top: 20px;
            font-size: 20px;
          }
        }
        &__cards{
          display: flex;
          flex-direction: column;
          gap: min(max(18px, calc(1.125rem + ((1vw - 7.68px) * 0.8681))), 28px);
          padding: min(max(12px, calc(0.75rem + ((1vw - 7.68px) * 0.6944))), 20px);
          padding-right: min(max(12px, calc(0.75rem + ((1vw - 7.68px) * 0.6944))), 20px);
          &__top{
            display: flex;
            justify-content: space-between;
            width: 100%;
            @media(max-width:867px){
              flex-direction: column;
              gap: 20px;
            }
            &__1{
              background-size: cover;
              background-position: center;
              cursor: pointer;
              position: relative;
              ::before {
                content: '';
                position: absolute;
                width: 100%;
                height: 100%;
                background-color: rgba(0, 0, 0, 0.6); /* Adjust the alpha (opacity) value as needed */
                opacity: 0.1; /* Set the desired opacity for the overlay */
                top: 0;
                left: 0;
              }
              &:hover{
                background-size: 105% 105%;
                @media(min-width:867px){
                &__button{
                  background: white !important;
                  color: black !important;
                }
                }
              }
              width: 49%;
              aspect-ratio: 1.96;
              display: flex;
              transition: all 0.5s ease;
              flex-direction: column;
              align-items: center;
              justify-content: center;
              gap: min(max(30px, calc(1.875rem + ((1vw - 7.68px) * 1.7361))), 50px);
              @media(max-width:867px){
                    width: 100%;
                    height: min(max(150px, calc(9.375rem + ((1vw - 2.5px) * 45.2196))), 500px);
                    background-size: cover;
                    gap: 20px;
              }
              &__title{
                color: white;
                text-align: center;
                font-size: min(max(17px, calc(1.0625rem + ((1vw - 10.24px) * 0.8929))), 25px);
                font-weight: 700;
                z-index: 5;
                text-transform: uppercase;
                @media(max-width:1024px){
                  padding-top: min(max(60px, calc(3.75rem + ((1vw - 2.5px) * 11.6279))), 150px);
                  font-size: min(max(12px, calc(0.75rem + ((1vw - 2.5px) * 0.9044))), 19px);
                }
              }
              &__button{
                display: flex;
                width: 30%;
                align-items: center;
                justify-content: center;
                height: min(max(45px, calc(2.8125rem + ((1vw - 10.24px) * 2.7902))), 70px);
                border: 1px solid #FFF;
                background-color: inherit;
                color: #FFF;
                font-weight: 700;
                line-height: normal;
                text-transform: uppercase;
                z-index: 4;
                @media(max-width:867px){
                  height: 30px;
                  font-size: 8px;
                  width: 25%;
                }
                
              }
            }
            &__2{
              background-size: cover;
              cursor: pointer;
              background-position: center;
              position: relative;
              ::before {
                content: '';
                position: absolute;
                width: 100%;
                height: 100%;
                background-color: rgba(0, 0, 0, 0.6); /* Adjust the alpha (opacity) value as needed */
                opacity: 0.1; /* Set the desired opacity for the overlay */
                top: 0;
                left: 0;
              }
              &:hover{
                @media(min-width:867px){
                background-size: 105% 105%;
                &__button{
                  background: white !important;
                  color: black !important;
                }
                }
              }
              width: 49%;
              aspect-ratio: 1.96;
              display: flex;
              transition: all 0.5s ease;
              flex-direction: column;
              align-items: center;
              justify-content: center;
              gap: min(max(30px, calc(1.875rem + ((1vw - 7.68px) * 1.7361))), 50px);
              @media(max-width:867px){
                  width: 100%;
                  height: min(max(150px, calc(9.375rem + ((1vw - 2.5px) * 45.2196))), 500px);
                  background-size: cover;
                  gap: 20px;
            }
              &__title{
                color: white;
                text-align: center;
                font-size: min(max(17px, calc(1.0625rem + ((1vw - 10.24px) * 0.8929))), 25px);
                font-weight: 700;
                z-index: 5;
                text-transform: uppercase;
                @media(max-width:867px){
                  padding-top: min(max(60px, calc(3.75rem + ((1vw - 2.5px) * 11.6279))), 150px);
                  font-size: min(max(12px, calc(0.75rem + ((1vw - 2.5px) * 0.9044))), 19px);
                }
              }
              &__button{
                display: flex;
                width: 30%;
                align-items: center;
                justify-content: center;
                height: min(max(45px, calc(2.8125rem + ((1vw - 10.24px) * 2.7902))), 70px);
                border: 1px solid #FFF;
                background-color: inherit;
                color: #FFF;
                font-weight: 700;
                line-height: normal;
                text-transform: uppercase;
                z-index: 2;
                @media(max-width:867px){
                  height: 30px;
                  font-size: 8px;
                  width: 25%;

            }
              }
            }
          }
          &__bot{
            display: flex;
            justify-content: space-between;
            width: 100%;
            @media(max-width:867px){
              flex-direction: column;
              gap: 20px;
            }
            &__1{
              width: 32%;
              aspect-ratio: 1.285;
              display: flex;
              cursor: pointer;
              flex-direction: column;
              align-items: center;
              justify-content: center;
              gap: min(max(30px, calc(1.875rem + ((1vw - 7.68px) * 1.7361))), 50px);
              background-size: cover;
              transition: all 0.5s ease;
              background-position: center;
              &:hover{
                @media(min-width:867px){
                background-size: 105% 105%;
                &__button{
                  background: white !important;
                  color: black !important;
                }
                }
              }
              position: relative;
              ::before {
                content: '';
                position: absolute;
                width: 100%;
                height: 100%;
                background-color: rgba(0, 0, 0, 0.6); /* Adjust the alpha (opacity) value as needed */
                opacity: 0.1; /* Set the desired opacity for the overlay */
                top: 0;
                left: 0;
              }
              @media(max-width:867px){
                  width: 100%;
                  height: min(max(150px, calc(9.375rem + ((1vw - 2.5px) * 45.2196))), 500px);
                  background-size: cover;
                  gap: 20px;
              }
              &__title{
                color: white;
                text-align: center;
                font-size: min(max(17px, calc(1.0625rem + ((1vw - 10.24px) * 0.8929))), 25px);
                font-weight: 700;
                z-index: 5;
                text-transform: uppercase;
                @media(max-width:1024px){
                  padding-top: min(max(60px, calc(3.75rem + ((1vw - 2.5px) * 11.6279))), 150px);
                  font-size: min(max(12px, calc(0.75rem + ((1vw - 2.5px) * 0.9044))), 19px);
                }
              }
              &__button{
                display: flex;
                width: 30%;
                align-items: center;
                justify-content: center;
                height: min(max(45px, calc(2.8125rem + ((1vw - 10.24px) * 2.7902))), 70px);
                border: 1px solid #FFF;
                background-color: inherit;
                color: #FFF;
                font-weight: 700;
                z-index: 2;
                line-height: normal;
                text-transform: uppercase;
                @media(max-width:1100px){
              height: 50px;
          }
                @media(max-width:867px){
                  height: 30px;
                  font-size: 8px;
                  width: 25%;
                }
                
              }
            }
            &__2{
              width: 32%;
              aspect-ratio: 1.285;
              display: flex;
              cursor: pointer;
              flex-direction: column;
              align-items: center;
              justify-content: center;
              gap: min(max(30px, calc(1.875rem + ((1vw - 7.68px) * 1.7361))), 50px);
              background-size: cover;
              transition: all 0.5s ease;
              background-position: center;
              &:hover{
                @media(min-width:867px){
                background-size: 105% 105%;
                &__button{
                  background: white !important;
                  color: black !important;
                }
                }
              }
              position: relative;
              ::before {
                content: '';
                position: absolute;
                width: 100%;
                height: 100%;
                background-color: rgba(0, 0, 0, 0.6); /* Adjust the alpha (opacity) value as needed */
                opacity: 0.1; /* Set the desired opacity for the overlay */
                top: 0;
                left: 0;
              }
              @media(max-width:867px){
                  width: 100%;
                  height: min(max(150px, calc(9.375rem + ((1vw - 2.5px) * 45.2196))), 500px);
                  background-size: cover;
                  gap: 20px;
              }
              &__title{
                color: white;
                text-align: center;
                font-size: min(max(17px, calc(1.0625rem + ((1vw - 10.24px) * 0.8929))), 25px);
                font-weight: 700;
                z-index: 5;
                text-transform: uppercase;
                @media(max-width:1024px){
                  padding-top: min(max(60px, calc(3.75rem + ((1vw - 2.5px) * 11.6279))), 150px);
                  font-size: min(max(12px, calc(0.75rem + ((1vw - 2.5px) * 0.9044))), 19px);
                }
              }
              &__button{
                display: flex;
                width: 30%;
                align-items: center;
                justify-content: center;
                height: min(max(45px, calc(2.8125rem + ((1vw - 10.24px) * 2.7902))), 70px);
                border: 1px solid #FFF;
                background-color: inherit;
                color: #FFF;
                font-weight: 700;
                z-index: 2;
                line-height: normal;
                text-transform: uppercase;
                @media(max-width:1100px){
              height: 50px;
          }
                @media(max-width:867px){
                  height: 30px;
                  font-size: 8px;
                  width: 25%;
                }
                
              }
            }
            &__3{
              width: 32%;
              aspect-ratio: 1.285;
              display: flex;
              cursor: pointer;
              flex-direction: column;
              align-items: center;
              justify-content: center;
              gap: min(max(30px, calc(1.875rem + ((1vw - 7.68px) * 1.7361))), 50px);
              background-size: cover;
              transition: all 0.5s ease;
              background-position: center;
              &:hover{
                @media(min-width:867px){
                background-size: 105% 105%;
                &__button{
                  background: white !important;
                  color: black !important;
                }
                }
              }
              position: relative;
              ::before {
                content: '';
                position: absolute;
                width: 100%;
                height: 100%;
                background-color: rgba(0, 0, 0, 0.6); /* Adjust the alpha (opacity) value as needed */
                opacity: 0.1; /* Set the desired opacity for the overlay */
                top: 0;
                left: 0;
              }
              @media(max-width:867px){
                  width: 100%;
                  height: min(max(150px, calc(9.375rem + ((1vw - 2.5px) * 45.2196))), 500px);
                  background-size: cover;
                  gap: 20px;
              }
              &__title{
                color: white;
                text-align: center;
                font-size: min(max(17px, calc(1.0625rem + ((1vw - 10.24px) * 0.8929))), 25px);
                font-weight: 700;
                z-index: 5;
                text-transform: uppercase;
                @media(max-width:1024px){
                  padding-top: min(max(60px, calc(3.75rem + ((1vw - 2.5px) * 11.6279))), 150px);
                  font-size: min(max(12px, calc(0.75rem + ((1vw - 2.5px) * 0.9044))), 19px);
                }
              }
              &__button{
                display: flex;
                width: 30%;
                align-items: center;
                justify-content: center;
                height: min(max(45px, calc(2.8125rem + ((1vw - 10.24px) * 2.7902))), 70px);
                border: 1px solid #FFF;
                background-color: inherit;
                color: #FFF;
                font-weight: 700;
                z-index: 2;
                line-height: normal;
                text-transform: uppercase;
                @media(max-width:1100px){
              height: 50px;
          }
                @media(max-width:867px){
                  height: 30px;
                  font-size: 8px;
                  width: 25%;
                }
                
              }
            }
          }
        }
      }
      &__block4{
        display: flex;
        flex-direction: column;
        gap: min(max(40px, calc(2.5rem + ((1vw - 10.24px) * 1.6741))), 55px);
        padding-top: min(max(40px, calc(2.5rem + ((1vw - 10.24px) * 1.6741))), 55px);
        padding-bottom: min(max(70px, calc(4.375rem + ((1vw - 10.24px) * 3.3482))), 100px);
        font-family: -apple-system,system-ui,Segoe UI,Roboto,Ubuntu,Cantarell,Noto Sans,sans-serif,STHeiti,Microsoft YaHei,Arial;
        @media(max-width:867px){
            gap: 20px;
            padding-top: 20px;
        }
        &__title{
          text-align: center;
          font-size: min(max(20px, calc(1.25rem + ((1vw - 10.24px) * 1.6741))), 35px);
          font-weight: 700;
          line-height: normal;
          letter-spacing: 6.912px;
          text-transform: uppercase;
          color: #282828;
          @media(max-width:867px){
            font-size: 24px;
        }
        
        }
        &__cards  {
          display: flex;
          flex-wrap: wrap;
          padding: 20px;
          gap: 2%;
          row-gap: 32px;
          align-items: stretch;
          &__card{
            width: 32%;
            cursor: pointer;
            display: flex;
            flex-direction: column;
            gap: 20px;
            font-family: -apple-system,system-ui,Segoe UI,Roboto,Ubuntu,Cantarell,Noto Sans,sans-serif,STHeiti,Microsoft YaHei,Arial;
            @media(max-width:768px){
              width: 100%;
            }
              &__image{
                display: flex;
                align-items: center;
                justify-content: center;
                width: 100%;
                aspect-ratio: 1.53;
                img{
                  width: 100%;
                  height: 100%;
                  object-fit: cover;
                }
              }
            &__title{
              color: #333;
              font-size: min(max(12px, calc(0.75rem + ((1vw - 10.24px) * 0.558))), 17px);
              font-style: normal;
              font-weight: 700;
              line-height: 38.4px;
              flex-grow: 1;
            }
            &__bot{
              display: flex;
              justify-content: space-between;
              align-items: center;
              &__date{
                color: #282828;
                font-size: min(max(10px, calc(0.625rem + ((1vw - 10.24px) * 0.4464))), 14px);
                font-style: normal;
                font-weight: 700;
                line-height: normal;
              }
              &__button{
                button{
                border: 3px solid #000;
                background: #FFF;
                transition: all 0.5s ease;
                cursor: pointer;
                &:hover{
                  background: #282828;
                  color: white;
                }
                height: min(max(45px, calc(2.8125rem + ((1vw - 10.24px) * 2.6786))), 69px);
                color: #282828;
                font-size: 15px;
                font-weight: 700;
                width: min(max(140px, calc(8.75rem + ((1vw - 10.24px) * 6.6964))), 200px);
                @media(max-width:867px){
                  height: 30px;
                  width: 130px;
                }
                }
              }
            }
          }
        }
      }
      &__block5{
        display: flex;
        flex-direction: column;
        gap: 100px;
        padding-bottom: 100px;
        &__title{
          color: #282828;
          text-align: center;
          font-size: 35px;
          font-family: Microsoft YaHei;
          font-style: normal;
          font-weight: 700;
          line-height: normal;
          letter-spacing: 6.912px;
          text-transform: uppercase;
        }
        &__cards{
          display: flex;
          flex-wrap: wrap;
          gap: 28px;
          @media(max-width:867px){
              flex-direction: column;
            }
          &__card{
            width: 32%;
            display: flex;
            flex-direction: column;
            transition: all 0.5s ease;
            @media(max-width:867px){
              width: 100%;
            }
            &:hover{
              -webkit-box-shadow: 11px 10px 62px 0px rgba(79, 136, 180, 0.3);
              -moz-box-shadow: 11px 10px 62px 0px rgba(79, 136, 180, 0.3);
              box-shadow: 11px 10px 62px 0px rgba(79, 136, 180, 0.3);
              transform: translateY(-10px);
            }
            &__top{
              display: flex;
              justify-content: space-between;
              padding-top: 8px;
              background: #F3F3F3;
              padding-bottom: 35px;
              &__left{
                width: 20%;
                img{
                  @media(max-width:867px){
                    width: 60px;
                    height: 60px;
                }
                }
              }
              &__right{
                width: 80%;
                display: flex;
                flex-direction: column;
                gap: 9px;
                &__title{
                  color: #282828;
                  font-size: 16px;
                  font-family: Microsoft YaHei;
                  font-style: normal;
                  font-weight: 700;
                  line-height: 38.4px;
                }
                &__subtitle{
                  color: #282828;
                  font-size: 16px;
                  font-family: Microsoft YaHei;
                  font-style: normal;
                  font-weight: 700;
                  line-height: 38.4px;
                }
                &__date{
                  color: #667785;
                  font-size: 15px;
                  font-family: Microsoft YaHei;
                  font-style: normal;
                  font-weight: 700;
                  line-height: 34.56px;
                }
              }
            }
            &__image{
              overflow: hidden;
              height: 350px;
              display: flex;
              align-items: center;
              justify-content: center;
              padding-bottom: 35px;
              @media(max-width:867px){
                height: 250px;
                img{
                width: 100%;
                height: 250px;
                }
            }
            }
            &__bot{
              display: flex;
              gap: 20px;
              align-items: center;
              margin-left: 20px;
              margin-bottom: 8px;
              &__comments{
                display: flex;
                gap: 8px;
                align-items: center;
                color: #911e31;
                font-size: 18px;
                font-family: Microsoft YaHei;
                font-style: normal;
                font-weight: 400;
                line-height: normal;   
              }
              &__likes{
                display: flex;
                gap: 8px;
                align-items: center;
                color: #911e31;
                font-size: 18px;
                font-family: Microsoft YaHei;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
              }
            }
          }
        }
      }

    }
</style>
<template>
    <div class="allur">
        <div class="allur__banner">
            <img src="@/assets/icons/allurBanner.png"/>
            <p class="allur__banner__title">{{ $t('allur1') }}</p>
        </div>
        <div class="allur__about container">
            <div class="allur__about__left">
                <img src="@/assets/icons/allur1.png"/>
            </div>
            <div class="allur__about__right">
                <p class="allur__about__right__title">{{ $t('allur2') }}</p>
                <p><span>Allur</span> - {{ $t('allur3') }}
                <br/><br/><br/><span>{{ $t('allur4') }}</span> {{ $t('allur5') }}
                <br/><br/><br/><span>{{ $t('allur6') }}</span> {{ $t('allur7') }}
                </p>
            </div>
        </div>
        <div class="allur__video container">
            <video controls>
                <source src="@/assets/videos/allur.mp4" type="video/mp4" />
            </video>
        </div>
        <div class="allur__digital container">
            <div class="allur__digital__title">
                <p>{{ $t('allur8') }}</p>
            </div>
            <div class="allur__digital__block">
                <div class="allur__digital__block__in" v-for="item in digital" :key="item">
                    <p class="allur__digital__block__in__title">{{item.title}}</p>
                    <p class="allur__digital__block__in__text">{{item.text}}</p>
                </div>
            </div>
        </div>
        <div class="line"></div>
        <div class="allur__industry container">
            <div class="allur__industry__title">
                <p>{{ $t('allur9') }}</p>
            </div>
            <div class="allur__industry__text">
                <p>{{ $t('allur10') }}</p>
            </div>
            <div class="allur__industry__block">
                <div class="allur__industry__block__in" v-for="item in block" :key="item">
                    <img :src="require('@/assets/icons/' + item.img + '.svg')"/>
                    <p>{{item.text}}</p>
                </div>
            </div>
            <div class="allur__industry__img">
                <swiper 
                    :slidesPerView="1" 
                    :pagination="{ 
                                clickable: true, 
                    }"
                    :lazy="true" 
                    :loop="true"
                > 
                    <swiper-slide v-for="(item) in swiper" :key="item" > 
                        <img :src="require('@/assets/icons/' + item.img + '.jpeg')" class="swiperImg"/>
                    </swiper-slide> 
                </swiper>
            </div>
        </div>
        <div class="allur__zavod container">
            <div class="allur__zavod__left">
                <img src="@/assets/icons/zavod.png"/>
            </div>
            <div class="allur__zavod__right">
                <p class="allur__zavod__right__title">
                    {{ $t('allur11') }}
                </p>
                <p class="allur__zavod__right__text">{{ $t('allur12') }}</p>
                <div class="allur__zavod__right__block">
                    <div class="allur__zavod__right__block__in" v-for="item in zavod" :key="item">
                        <img :src="require('@/assets/icons/' + item.img + '.svg')"/>
                        <p class="allur__zavod__right__block__in__title">{{item.name}}</p>
                        <p class="allur__zavod__right__block__in__value">{{item.value}}</p>
                    </div>
                </div>
            </div>
        </div>
        <div class="allur__net container">
            <div class="allur__net__left">
                <p class="allur__net__left__title">{{ $t('allur13') }}</p>
                <p class="allur__net__left__text">{{ $t('allur14') }}
</p>
            </div>
            <div class="allur__net__right">
                <img src="@/assets/icons/net.png"/>
            </div>
        </div>
        <div class="allur__button">
            <a href="https://allur.kz/"><button>{{ $t('allur15') }}</button></a>
        </div>
    </div>
</template>
<script>
import { Swiper, SwiperSlide,} from "swiper/vue"; 
import "swiper/css"; 
import "swiper/css/pagination"; 
import "swiper/css/navigation";  
import "swiper/css/scrollbar";
import { useHead } from "@vueuse/head"
import SwiperCore, { A11y, Autoplay, Scrollbar } from "swiper"; 
import { Navigation, Pagination } from 'swiper'; 
import "swiper/swiper.min.css"; 
SwiperCore.use([Navigation,Pagination, A11y, Autoplay])
export default {
    data(){
        return{

           digital: [
            {
                title: '15 апреля 2003 г.',
                text: 'Дата основания'
            },
            {
                title: 'Более 5000 человек',
                text: 'Количество сотрудников'
            },
            {
                title: '3 города присутствия',
                text: 'Города присутствия: Астана (головной офис, розница), Алматы (дистрибуция, розница), Костанай (производство)'
            },
            {
                title: '6 брендов',
                text: 'Портфель брендов:(Kia, Chevrolet, JAC, Jetour, Hongqi и Skoda)'
            },
           ],
           block: [
            {
                img: 'block1',
                text: 'Производственная мощность: 127 600 автомобилей в год '
            },
            {
                img: 'block2',
                text: 'Доля рынка по производству: 61%'
            },
           ],
           swiper: [
            {
                img: 'swiper'
            },
            {
                img: 'swiper1'
            },
            {
                img: 'swiper2'
            },
            {
                img: 'swiper3'
            },
           ],
           zavod: [
            {
                img: 'zavod1',
                name: 'Дата основания',
                value: 'Май 2010 г.'
            },
            {
                img: 'zavod2',
                name: 'Количество сотрудников',
                value: 'Более 3500'
            },
            {
                img: 'zavod3',
                name: 'Территория',
                value: '356 000 кв.м.'
            },
           ]
        }
    },
    components: { Swiper, SwiperSlide },
}
</script>
<style lang="scss" scoped>
.swiperImg{
    width: 100%;
    aspect-ratio: 1.5;
}
    .allur{
        display: flex;
        margin-bottom: 80px;
        flex-direction: column;
        gap: 70px;
        &__banner{
            position: relative;
            img{
                width: 100%;
                aspect-ratio: 2.7;
            }
            &::before {
                content: '';
                position: absolute;
                width: 100%;
                height: 100%;
                background-color: rgba(0, 0, 0, 0.6); /* Adjust the alpha (opacity) value as needed */
                opacity: 0.5; /* Set the desired opacity for the overlay */
                top: 0;
                left: 0;
              }
            
            &__title{
                position: absolute;
                color: white;
                font-size: min(max(30px, calc(1.875rem + ((1vw - 7.68px) * 2.6042))), 60px);
                top:37%;
                left: 10%;  
            }
        }
        &__about{
            display: flex;
            justify-content: space-between;
            &__left{
                width: 50%;
                img{
                    width: 100%;
                    aspect-ratio: 1.3;
                }
            }
            &__right{
                width: 50%;
                display: flex;
                flex-direction: column;
                gap: 40px;
                padding: 40px;
                span{
                    font-weight: 700;
                }
                p{
                    font-size: 18px;
                }
                &__title{
                    font-size: 40px !important;
                }
            }
        }
        &__video{
            width: 100%;
            height: 90vh;
            video{
                width: 100%;
                height: 100%;
            }
        }
        &__digital{
            display: flex;
            flex-direction: column;
            gap: 80px;
            padding-top: 80px;
            &__title{
                text-align: center;
                font-size: 40px;
            }
            &__block{
                display: flex;
                gap: 2%;
                justify-content: center;
                &__in{
                    width: 22%;
                    border: 1px solid #D9D9D9;
                    border-radius: 35px;
                    padding: 70px 20px;
                    display: flex;
                    flex-direction: column;
                    gap: 20px;
                    align-items: center;
                    text-align: center;
                    justify-content: center;
                    background: #353535;
                    color: white;
                    &__title{
                        font-size: 18px;
                        font-weight: 700;
                    }
                }
            }
        }
        &__industry{
            display: flex;
            flex-direction: column;
            gap: 50px;
            &__title{
                font-size: 40px;
                text-align: center;
            }
            &__text{
                text-align: center;
                width: 45%;
                margin-left: auto;
                margin-right: auto;
            }
            &__block{
                display: flex;
                justify-content: center;
                gap: 150px;
                img{
                    width: 60px;
                    height: 60px;
                }
                &__in{
                    width: 15%;
                    text-align: center;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    gap: 25px;
                    font-size: 18px;
                }
            }
            &__img{
                width: 100%;
                img{
                    width: 100%;
                    aspect-ratio: 2.5;
                    object-fit: cover;
                }
            }
        }
        &__zavod{
           display: flex;
           justify-content: space-between; 
           &__left{
            width: 50%;
            img{
                width: 100%;
                aspect-ratio: 1.3;
            }
           }
           &__right{
            width: 50%;
            padding: 40px;
            display: flex;
            flex-direction: column;
            gap: 60px;
            &__title{
                font-size: 40px;
            }
            &__text{
                font-size: 18px;
            }
            &__block{
                display: flex;
                gap: 5%;
                &__in{
                    width: 30%;
                    aspect-ratio: 1;
                    border: 1px solid #000000;
                    border-radius: 50%;
                    display: flex;
                    flex-direction: column;
                    gap: 10px;
                    align-items: center;
                    justify-content: center;
                    font-size: 14px;
                    text-align: center;
                }
            }
           }
        }
        &__net{
            display: flex;
            justify-content: space-between;
            &__left{
                width: 50%;
                padding: 40px;
                display: flex;
                flex-direction: column;
                gap: 40px;
                &__title{
                font-size: 40px;
                }
                &__text{
                    font-size: 18px;
                }
            }
            &__right{
                width: 50%;
                img{
                    width: 100%;
                    aspect-ratio: 1.3;
                }
            }
        }
        &__button{
            margin-left: auto;
            margin-right: auto;
            margin-top: 40px;
            margin-bottom: 40px;
            width: 12%;
            button{
                width: 100%;
                border: 2px solid #000000;
                background-color:transparent;
                padding: 20px 0px;
                font-weight: 600;
                cursor: pointer;
            }
        }
        @media(max-width:768px){
            margin-bottom: 20px;
            gap: 40px;
            &__about{
                flex-direction: column;
                &__left{
                    width: 100%;
                }
                &__right{
                    width: 100%;
                    padding: 40px 20px;
                    gap: 20px;
                    &__title{
                        font-size: 22px !important;
                    }
                    p{
                        font-size: 15px;
                    }
                }
            }
            &__digital{
                padding-top: 0px !important;
                width: 90%;
                gap: 20px;
                &__title{
                    font-size: 22px;
                }
                &__block{
                    flex-direction: column;
                    gap: 20px;
                    &__in{
                        width: 100%;
                    }
                }
            }
            &__industry{
                gap: 20px;
                &__title{
                    font-size: 22px;
                }
                &__text{
                    font-size: 15px;
                    width: 100%;
                }
                &__block{
                    gap: 0px !important;
                    justify-content: space-between;
                    &__in{
                        width: 45%;
                        font-size: 13px;
                    }
                }
            }
            &__zavod{
                flex-direction: column;
                &__left{
                    width: 100%;
                }
                &__right{
                    width: 100%;
                    padding: 40px 20px;
                    gap: 20px;
                    &__title{
                        font-size: 22px;
                    }
                    &__text{
                        font-size: 15px;
                    }
                    &__block{
                        flex-direction: column;
                        justify-content: center;
                        align-items: center;
                        gap: 20px;
                        &__in{
                            width: 50%;
                            border-radius: 50px;
                            font-size: 14px;
                        }
                    }
                }
            }
            &__net{
                flex-direction: column-reverse;
                &__left{
                    width: 100%;
                    padding: 40px 20px;
                    gap: 20px;
                    &__title{
                        font-size: 22px;
                    }
                    &__text{
                        font-size: 15px;
                    }
                }
                &__right{
                    width: 100%;
                }
            }
            &__button{
                width: 90%;
            }
            &__video{
                height: 30vh;
            }
        }
    }
</style>
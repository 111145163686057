import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import NewsView from '../views/NewsView.vue'
import WorldView from '../views/WorldView.vue'
import ContactUsView from '../views/ContactUsView.vue'
import DilersView from '../views/DilersView.vue'
import LibraryView from '../views/LibraryView.vue'
import ModelsView from '../views/ModelsView.vue'
import ModelsSlugView from '../views/ModelsSlugView.vue'
import HistoryView from '../views/HistoryView.vue'
import BrandView from '../views/BrandView.vue'
import OwnersView from '../views/OwnersView.vue'
import DevelopView from '../views/DevelopView.vue'
import AchievementsView from '../views/AchievementsView.vue'
import SocialView from '../views/SocialView.vue'
import Allur from '../views/AboutAllur.vue'
import NewsCardView from '../views/NewsCardView.vue'
import newBrandView from '@/views/newBrandView.vue'
import TestDrive from '@/views/Test-drive.vue'

const handleSlashAndWwwMiddleware = (to, from, next) => {
  const host = window.location.host
  const startsWithWww = host.startsWith('www.')
  const hasTrailingSlash = to.path.endsWith('/')

  if (to.path === '/') {
    // Добавляем слеш для главной страницы
    if (!hasTrailingSlash) {
      next({ path: to.path + '/' })
    } else {
      next()
    }
  } else {
    let newPath = to.path

    // Убираем слеш в конце URL
    if (hasTrailingSlash) {
      newPath = to.path.slice(0, -1)
      next(newPath)
    } else {
      next()
    }
  }
}
const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
  },
  {
    path: '/owner',
    name: 'owner',
    component: OwnersView,
  },
  {
    path: '/allur',
    name: 'allur',
    component: Allur,
  },
  {
    path: '/test-drive',
    name: 'test-drive',
    component: TestDrive,
  },
  {
    path: '/news/:slug',
    name: 'newsslug',
    component: NewsCardView,
  },
  {
    path: '/social',
    name: 'social',
    component: SocialView,
  },
  {
    path: '/achievement',
    name: 'achievement',
    component: AchievementsView,
  },
  {
    path: '/develop',
    name: 'develop',
    component: DevelopView,
  },
  {
    path: '/:catchAll(.*)',
    redirect: '/',
  },
  {
    path: '/brand',
    name: 'brand',
    component: newBrandView,
  },
  {
    path: '/history',
    name: 'history',
    component: HistoryView,
  },
  {
    path: '/news',
    name: 'news',
    component: NewsView,
  },
  {
    path: '/dealers',
    name: 'dealers',
    component: DilersView,
  },
  {
    path: '/library',
    name: '/library',
    component: LibraryView,
  },
  {
    path: '/models/:slug',
    name: '/modelSlug',
    component: ModelsSlugView,
  },
  {
    path: '/world',
    name: 'world',
    component: WorldView,
  },
  {
    path: '/contactus',
    name: 'contactus',
    component: ContactUsView,
  },
  {
    path: '/model',
    name: 'model',
    component: ModelsView,
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {

    if (to.hash) {
      const el = document.querySelector(to.hash);
      if (el) {
        setTimeout(() => {
          const scrollTop = el.getBoundingClientRect().top + window.pageYOffset - 0;
          window.scrollTo({
            top: scrollTop,
            behavior: 'smooth'
          });
        }, 0);
        return false;
      }
    } else {
      if (savedPosition) {
        return savedPosition;
      } else {
        setTimeout(() => {
          window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth'
          });
        }, 0);
      }
    }
  }
})
router.beforeEach((to, from, next) => {
  handleSlashAndWwwMiddleware(to, from, next)
})
// Middleware для удаления префикса "www." и слеша в конце UR

export default router

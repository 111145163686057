<template>
    <div class="hat">
        <div class="hatline"></div>
        <div class="hat__title">
            <router-link to="/models/h9" active-class="active-link" style="text-decoration: none; color: inherit;">
                <p>H9</p>
            </router-link>
            <router-link to="/models/e-hs9" active-class="active-link" style="text-decoration: none; color: inherit;">
                <p>E-HS9</p>
            </router-link>
            <router-link to="/models/h5" active-class="active-link" style="text-decoration: none; color: inherit;">
                <p>H5</p>
            </router-link>
            <router-link to="/models/hs5" active-class="active-link" style="text-decoration: none; color: inherit;">
                <p>HS5</p>
            </router-link>
        </div>
        <div class="hatline"></div>
    </div>
    <div class="video-background">
        <video autoplay muted playsinline v-if="model?.model?.video">
            <source :src="model.model.video" type="video/mp4" />
        </video>
        <router-link to="/h5#test"><button class="custom-button">ТЕСТ-ДРАЙВ</button></router-link>
        <p class="custom-text" v-if="model?.model?.title">HONGQI<br>
            {{ model.model.title }}</p>
    </div>
    <div class="cars" v-if="model">
        <div class="selected-car">
            <img v-if="model?.colors" :src="model.colors[this.activeCarIndex].image" alt="" />
        </div>
        <div class="selected-img">
            <div v-for="(car, index) in model.colors" :key="index" @click="selectCar(index)"
                :class="{ active: index === activeCarIndex }" class="imgcolor">
                <img :src="car.hex" />
            </div>
        </div>
        <div class="desctop">
            <div class="param container">
                <div class="param__title">
                    <p>{{ model.complectations[0].title }}</p>
                </div>
                <div class="param__main">
                    <div class="param__main__left">
                        <div class="param__main__left__1">
                            <p class="character">{{ model.mainSpecification.specifications[0].title }}</p>
                            <p class="character__info">{{ model.mainSpecification.specifications[0].value_for_1 }}</p>
                        </div>
                        <div class="param__main__left__2">
                            <p class="character">{{ model.mainSpecification.specifications[1].title }}</p>
                            <p class="character__info">{{ model.mainSpecification.specifications[1].value_for_1 }}</p>
                        </div>
                    </div>
                    <div class="line"></div>
                    <div class="param__main__left">
                        <div class="param__main__left__1">
                            <p class="character">{{ model.mainSpecification.specifications[2].title }}</p>
                            <p class="character__info">{{ model.mainSpecification.specifications[2].value_for_1 }}</p>
                        </div>
                        <div class="param__main__left__2">
                            <p class="character">{{ model.mainSpecification.specifications[3].title }}</p>
                            <p class="character__info">{{ model.mainSpecification.specifications[3].value_for_1 }}</p>
                        </div>
                    </div>
                    <div class="line"></div>
                    <div class="param__main__left">
                        <div class="param__main__left__1">
                            <p class="character">{{ model.mainSpecification.specifications[4].title }}</p>
                            <p class="character__info">{{ model.mainSpecification.specifications[4].value_for_1 }}</p>
                        </div>
                        <div class="param__main__left__2">
                            <p class="character">{{ model.mainSpecification.specifications[5].title }}</p>
                            <p class="character__info">{{ model.mainSpecification.specifications[5].value_for_1 }}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="mobile">
            <div class="parammobile">
                <div class="parammobile__in" v-for="(item) in model.mainSpecification.specifications" :key="item">
                    <div class="values">
                        <div class="parammobile__in__title">
                            {{ item.title }}
                        </div>
                        <div class="parammobile__in__value">
                            {{ item.value_for_1 }}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="parameters container" v-if="model">
        <div class="parameters__title">
            <p>{{ $t('params') }}</p>
        </div>
        <div class="parameters__block">
            <div class="parameters__block__top">
                <div class="parameters__block__top__left" v-for="item in model.complectations" :key="item.id">
                    <p>{{ item.title }}</p>
                    <p style="font-size: min(max(14px, calc(0.875rem + ((1vw - 10.24px) * 1.2277))), 25px);">{{
                        formatPrice(item.price) }} тг</p>
                </div>
            </div>
            <div class="parameters__block__bot">
                <div class="parameters__block__bot__in" v-for="item in model.specificationCategories" :key="item">
                    <div @click="toggleParameter(item)" class="parameters__block__bot__in__top">
                        <div class="parameters__block__bot__in__top__name">
                            {{ item.title }}
                        </div>
                        <img class="plus" src="@/assets/icons/minus.jpg" v-if="item.isOpen" style="cursor: pointer">
                        <img class="plus" src="@/assets/icons/plus.jpg" v-else style="cursor: pointer"/>
                    </div>
                    <div v-if="item.isOpen" class="parameters__block__bot__in__bot">
                        <div class="parameter" v-for="(parameter, index) in item.specifications" :key="parameter"
                            :class="{ 'even-background': index % 2 === 0 }">
                            <div class="parameter__first">{{ parameter.title }}</div>
                            <div class="parameter__second" v-if="parameter.value_for_1">{{ parameter.value_for_1 }}</div>
                            <div class="parameter__third" v-if="parameter.value_for_2">{{ parameter.value_for_2 }}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="price">
            <button @click="downloadPDF">
                Прайс лист
            </button>
            <button @click="downloadPDFb">
                Брошюра
            </button>
        </div>
    </div>
    <div class="mobile">
        <button class="openparameters">{{ $t('model_1') }}</button>
        <div class="parametermobileback" v-if="model">
            <div class="parametermobile">
                <div class="parametermobile__top">
                    <div class="parametermobile__top__left">
                        <p>Модель</p>
                    </div>
                    <div class="lineparameter"></div>
                    <div class="parametermobile__top__right">
                        <p>{{ selectedParam }}</p>
                    </div>
                </div>
                <div class="parametermobile__block2">
                    <p>{{ $t('model_1') }}</p>
                </div>
                <div class="lineparameter1"></div>
                <div class="parametermobile__block3" @click="openParam = !openParam">
                    <div class="parametermobile__block3__top">
                        <p>{{ selectedParam }}</p>
                        <img src="@/assets/icons/arrow.png" />
                    </div>
                    <div class="parametermobile__block3__bot" v-if="openParam">
                        <div v-for="(item, index) in model.complectations" :key="index"
                            class="parametermobile__block3__bot__1" :class="{ 'even-background1': index % 2 === 0 }">
                            <p @click="selectedParam = item.title">{{ item.title }}</p>
                        </div>
                    </div>
                </div>
                <div class="parametermobile__block4">
                    <div class="parametermobile__block4__in" v-for="item in model.specificationCategories" :key="item">
                        <div class="parametermobile__block4__in__top" @click="toggleParametermobile(item)">
                            <div class="parametmobile__block4__in__top__name">
                                <p>{{ item.title }}</p>
                            </div>
                            <img src="@/assets/icons/minus.jpg" v-if="item.isOpenmobile" />
                            <img src="@/assets/icons/plus.jpg" v-else />
                        </div>
                        <div class="parametermobile__block4__in__bot" v-if="item.isOpenmobile">
                            <div class="paramOpen" v-if="selectedParam == model.complectations[0].title"
                                v-for="(parameter, index) in item.specifications" :key="parameter"
                                :class="{ 'even-background': index % 2 === 0 }">
                                <div class="parametermobile__block4__in__bot">{{ parameter.title }}</div>
                                <div class="parametermobile__block4__in__bot" v-if="parameter.value_for_1">{{
                                    parameter.value_for_1 }}</div>
                            </div>
                            <div v-if="model.complectations[1]">
                                <div class="paramOpen" v-if="selectedParam == model.complectations[1].title"
                                    v-for="(parameter, index) in item.specifications" :key="parameter"
                                    :class="{ 'even-background': index % 2 === 0 }">
                                    <div class="parametermobile__block4__in__bot">{{ parameter.title }}</div>
                                    <div class="parametermobile__block4__in__bot" v-if="parameter.value_for_2">{{
                                        parameter.value_for_2 }}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="pricemobile" v-if="model">
            <button @click="downloadPDF">
                {{ $t('model_2') }}
            </button>
            <button @click="downloadPDFb">
                {{ $t('model_3') }}
            </button>
        </div>
    </div>
    <div class="main" v-if="model">
        <div class="main__title">
            {{ $t('model_4') }}
        </div>
        <template v-for="item in model.sections" :key="item.id">
            <img v-if="item.image" class="imgformain" :src="item.image" style="width: 100%;" />
            <div class="main__block1">
                <div class="main__block1__card" v-for="elem in item.sectionItems" :key="elem">
                    <img v-if="elem.image" :src="elem.image" />
                    <div class="main__block1__card__bot">
                        <p class="main__block1__card__bot__title">{{ elem.title }}</p>
                        <p class="main__block1__card__bot__subtitle">{{ elem.text }}</p>
                    </div>
                </div>
            </div>
        </template>
        <!-- <img class="imgformain" :src="model.sections[1].image" style="width: 100%;" />
        <div class="main__block2">
            <div class="main__block2__card" v-for="item in  model.sections[1].sectionItems" :key="item">
                <img :src="item.image" />
                <div class="main__block2__card__bot">
                    <p class="main__block2__card__bot__title">{{ item.title }}</p>
                    <p class="main__block2__card__bot__subtitle">{{ item.text }}</p>
                </div>
            </div>
        </div> -->
        <div class="info">
            <div class="info__image">
                <swiper ref="swiper" :slides-per-view="1" :thumbs="{ swiper: thumbsSwiper }" :modules="modules"
                    :speed="400">
                    <swiper-slide v-for="item in model.slider" :key="item" class="info__image-main">
                        <img :src="item.image" />
                    </swiper-slide>
                </swiper>
                <div class="info__image__swiper">
                    <swiper ref="swiper1" :speed="550" :modules="modules" :navigation="{
                        prevEl: '.arrow1block',
                        nextEl: '.arrow2block',
                        disabledClass: 'disabled'
                    }" :breakpoints="swiper.breakpoint2" @swiper="setThumbsSwiper" @slideChange="setThumbsSwiper">
                        <swiper-slide v-for="item in model.slider" :key="item" class="info__image__swiper__image">
                            <img :src="item.image" />
                        </swiper-slide>
                    </swiper>
                    <div class="arrow1block">
                        <img src="@/assets/icons/arrow1.svg" />
                    </div>
                    <div class="arrow2block">
                        <img src="@/assets/icons/arrow2.svg" />
                    </div>
                </div>
            </div>
        </div>
        <test-drive id="test" :modalTitle="modalTitle" />
    </div>
</template>
<script>
import { useHead } from "@vueuse/head"
import { Swiper, SwiperSlide, } from "swiper/vue";
import { useRouter, useRoute } from 'vue-router'
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { mask } from "vue-the-mask";
import SwiperCore, { A11y, Autoplay } from "swiper";
import { Thumbs, FreeMode, EffectFade } from "swiper";
import { Navigation, Pagination } from 'swiper';
import "swiper/swiper.min.css";
import TestDrive from '@/components/models/testDrive.vue';
SwiperCore.use([Navigation, Pagination, A11y, Autoplay])

export default {
    setup() {
        const route = useRoute()
        useHead({
            title: `Модель ${route.params.slug}`,
            meta: [
                { hid: 'description', name: 'description', content: 'Описание страницы' },
                { hid: 'canonical', rel: 'canonical', href: `https://hongqi-auto.kz/models/${route.params.slug}`},
            ],
        })
    },
    directives: { mask },
    components: {
        Swiper, SwiperSlide,
        TestDrive
    },
    data() {
        return {
            modalTitle: 'Тест-драйв',
            selectedParam: '',
            openParam: false,
            swiper: {
                breakpoint2: {
                    350: {
                        slidesPerView: 5,
                        spaceBetween: 10
                    },
                    768: {
                        slidesPerView: 5,
                        spaceBetween: 10
                    },
                    1440: {
                        slidesPerView: 5,
                        spaceBetween: 10
                    }
                },
            },
            modules: [Thumbs, FreeMode, EffectFade],
            thumbsSwiper: null,
            activeCarIndex: 0,
            model: false
        }
    },
    async created() {
        this.getPage()
    },
    watch: {
        $route() {
            this.model.model.video = false
            this.getPage()
        }
    },
    methods: {
        async getPage() {
            await this.$axios.get(`/model/get?slug=${this.$route.params.slug}`)
                .then(res => {
                    this.model = res.data
                    this.selectedParam = res.data.complectations[0].title
                })
                .catch(err => {
                })
        },
        formatPrice(price) {
            return price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
        },
        selectCar(index) {
            this.activeCarIndex = index;
        },
        toggleParameter(value) {
            value.isOpen = !value.isOpen;
        },
        toggleParametermobile(value) {
            value.isOpenmobile = !value.isOpenmobile;
        },
        setThumbsSwiper(swiper) {
            this.thumbsSwiper = swiper;
        },
        downloadPDF() {
            const link = document.createElement('a');
            if (this.$i18n.locale === 'ru') {
                link.href = this.model.model.price_list;
            } else if (this.$i18n.locale === 'kz' && this.model.model.price_list_kz) {
                link.href = this.model.model.price_list_kz;
            } else {
                link.href = this.model.model.price_list;
             }
            link.download = this.model.model.title;
            link.target = '_blank';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        },
        downloadPDFb() {
            const link = document.createElement('a');
            if (this.$i18n.locale === 'ru') {
                link.href = this.model.model.document;
            } else if (this.$i18n.locale === 'kz' && this.model.model.document_kz) {
                link.href = this.model.model.document_kz;
            } else {
                link.href = this.model.model.document;
                console.log('result', this.$i18n.locale)
             }
            link.download = this.model.model.title; // Имя файла, каким он будет скачан
            link.target = '_blank'; // Откроется в новой вкладке
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
    }
}
</script>
<style lang="scss" scoped>
.parammobile{
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
    justify-content: center;
    row-gap: 20px !important;
    gap: 4%;
    padding-left: 3%;
    padding-right: 3%;
    padding-top: 30px;
    margin-bottom: 30px;
    &__in{
        display: flex;
        width: 45%;
        &__title{
            color: #282828;
             font-weight: 700;
             font-size: 14px;
        }
        &__value{
            font-size: 14px;
        }
    }
    .values{
        display: flex;
        flex-direction: column;
        gap: 5px;
    }
}
.line2{
    border: 1px solid white;
            height: auto;
}
.even-background1 {
    background: grey !important;
}

.even-background {
    background: white !important;
}

.plus {
    width: min(max(24px, calc(1.5rem + ((1vw - 10.24px) * 1.7857))), 40px);
    height: min(max(24px, calc(1.5rem + ((1vw - 10.24px) * 1.7857))), 40px);
}

.pricemobile {
    margin-top: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    justify-content: center;

    button {
        font-size: 15px;
        border-radius: 50px;
        color: white;
        border: none;
        cursor: pointer;
        background: #42373c;
        width: 90%;
        padding: 15px 0px;
    }
}

.parametermobile {
    display: flex;
    margin-top: 30px;
    flex-direction: column;
    padding: 0px 20px;
    &__top {
        display: flex;
        align-items: center;
        color: white;
        &__left {
            width: 50%;
            padding: 20px 0px;
            text-align: center;
            background: #333;
        }

        &__right {
            width: 50%;
            padding: 20px 0px;
            text-align: center;
            background: #333;
        }

        .lineparameter {
            border: 1px solid white;
            height: auto;
        }
    }

    &__block2 {
        width: 100%;
        text-align: center;
        padding: 10px 0px;
        border: 1px solid grey
    }

    &__block3 {
        width: 100%;
        border: 1px solid grey;

        p {
            padding-left: 20px;
        }

        &__top {
            display: flex;
            padding: 10px 20px 10px 0px;
            justify-content: space-between;
            align-items: center;

            img {
                width: 22px;
                height: 22px;
            }
        }

        &__bot {
            display: flex;
            flex-direction: column;
            color: white;

            &__1 {
                background: black;
                padding: 12px;
            }
        }
    }

    &__block4 {
        display: flex;
        flex-direction: column;
        margin-top: 30px;

        &__in {
            padding: 10px;
            background-color: #efefef;
            border-bottom: 1px solid #fff;

            &__top {
                display: flex;
                justify-content: space-between;
                align-items: center;
            }
        }
    }

    .paramOpen {
        display: flex;
        justify-content: space-between;
        padding: 10px;
        align-items: center;
        box-sizing: border-box;
        background: #ccc;
        font-size: 12px;
        gap: 10px;

    }
}

.openparameters {
    margin-top: 30px;
    border-radius: 15px;
    font-size: 15px;
    width: 90%;
    height: 40px;
    border: none;
    background: grey;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: auto;
    margin-right: auto;
    color: white;
    text-transform: uppercase;
}

.price {
    margin-left: auto;
    margin-right: auto;
    display: flex;
    gap: min(max(30px, calc(1.875rem + ((1vw - 7.68px) * 1.7361))), 50px);
    button {
        cursor: pointer;
        padding: min(max(9px, calc(0.5625rem + ((1vw - 10.24px) * 0.6696))), 15px) min(max(54px, calc(3.375rem + ((1vw - 10.24px) * 4.0179))), 90px);
        font-size: min(max(16px, calc(1rem + ((1vw - 10.24px) * 0.8929))), 24px);
        border: none;
        background: #42373c;
        border-radius: 50px;
        color: white;

        &:hover {
            background: red;
            transition: all 0.5s ease;
            transform: scale(1.1);
            color: white;
        }
    }
}

.imgformain {
    width: 100%;
    aspect-ratio: 2.146;
    object-fit: cover;
    @media(max-width:867px) {
        display: none;
    }
}

.parameter {
    display: flex;
    padding: 20px;
    align-items: center;
    background: #ccc;
    justify-content: space-between;

    &__first {
        width: 20%;
        text-align: center;
    }

    &__second {
        width: 40%;
        text-align: center;
    }

    &__third {
        width: 40%;
        text-align: center;
    }

    &__img1 {
        width: 40%;
        text-align: center;
    }

    &__img2 {
        width: 40%;
        text-align: center;
    }
}

.imgcolor {
    width: min(max(42px, calc(2.625rem + ((1vw - 7.68px) * 3.2986))), 80px);
    height: min(max(42px, calc(2.625rem + ((1vw - 7.68px) * 3.2986))), 80px);
    cursor: pointer;
    @media(max-width:867px) {
        width: 40px;
        height: 40px;
    }

}

.param {
    display: flex;
    flex-direction: column;
    gap: min(max(20px, calc(1.25rem + ((1vw - 10.24px) * 2.2321))), 40px);

    &__title {
        text-align: center;
        margin-top: min(max(15px, calc(0.9375rem + ((1vw - 10.24px) * 3.9063))), 50px);
        font-size: min(max(20px, calc(1.25rem + ((1vw - 10.24px) * 2.2321))), 40px);
        color: #282828;
        font-weight: 700;
    }

    &__main {
        display: flex;
        justify-content: space-between;
        padding: 0px min(max(120px, calc(7.5rem + ((1vw - 10.24px) * 8.9286))), 200px) 80px min(max(120px, calc(7.5rem + ((1vw - 10.24px) * 8.9286))), 200px);

        &__left {
            display: flex;
            flex-direction: column;
            gap: min(max(25px, calc(1.5625rem + ((1vw - 10.24px) * 2.7902))), 50px);

            .character {
                font-size: min(max(10px, calc(0.625rem + ((1vw - 10.24px) * 1.1161))), 20px);
                color: #525252;

            }

            .character__info {
                color: #17181a;
                font-size: min(max(15px, calc(0.9375rem + ((1vw - 10.24px) * 1.6741))), 30px);
                font-weight: 600;

            }
        }
    }
}

.line {
    border: 1.5px solid black;

    @media(max-width:867px) {
        display: none;
    }
}

.active-link {
    color: red !important;
    transform: scale(1.8);
}

.hat {
    display: flex;
    padding: min(max(18px, calc(1.125rem + ((1vw - 7.68px) * 1.0417))), 30px) 0px;
    justify-content: space-between;
    align-items: center;
    background: black;
    opacity: 0.9;
    color: white;
    font-size: min(max(12px, calc(0.75rem + ((1vw - 10.24px) * 0.8929))), 20px);
    font-weight: 600;

    p {
        cursor: pointer;

        &:hover {
            color: red;
            transform: scale(1.2);
            transition: all 0.3s ease;
        }
    }

    &__title {
        display: flex;
        gap: 40px;
    }

    .hatline {
        width: 40%;
        height: 2px;
        background: #5a5a5a;

        @media(max-width:1400px) {
            width: 30%;
        }

        @media(max-width:1100px) {
            width: 30%;
        }

        @media(max-width:867px) {
            width: 10%;
        }
    }
}

.arrow1block {
    background-color: white;
    opacity: 0.6;
    position: absolute;
    z-index: 99;
    cursor: pointer;
    top: 28%;
    padding: 20px;
    @media(max-width:867px) {
       display: none;
    }
    img {
        width: 30px;
        height: 30px;
        @media(max-width:867px) {
            width: 20px;
            height: 20px;
        }

    }
}

.arrow2block {
    padding: 20px;
    background-color: white;
    width: 3%;
    aspect-ratio: 1;
    cursor: pointer;
    opacity: 0.6;
    position: absolute;
    z-index: 99;
    top: 28%;
    right: 1%;

    @media(max-width:867px) {
        display: none;
    }

    img {
        width: 30px;
        height: 30px;

        @media(max-width:867px) {
            width: 20px;
            height: 20px;
        }
    }
}

.info {
    display: flex;
    flex-direction: column;

    @media screen and (max-width: 768px) {
        // gap: 20px;
    }

    &__image {
        &-main {
            overflow: hidden;
            height: 100%;
            border-radius: 0px;

            @media screen and (max-width: 768px) {

            }
            img {
                width: 100%;
                aspect-ratio: 2.2;
                object-fit: cover;
                @media(max-width:867px) {
                    height: 40vh;
                }
            }
        }

        &__swiper {
            margin-top: 0px;
            background: #000;
            position: relative;

            &__image {
                overflow: hidden;
                border-radius: 0px;
                cursor: pointer;
                opacity: 0.6;
                @media(max-width:767px){
                    height: 100px;
                }
                img {
                    object-fit: cover;
                    width: 100%;
                    height: 100%;
                }
            }

            .swiper-slide {
                cursor: pointer;
                &-thumb-active {
                    opacity: 1.5;
                }
            }
        }
    }
}

.parameters {
    padding-top: min(max(60px, calc(3.75rem + ((1vw - 10.24px) * 4.4643))), 100px);
    display: flex;
    flex-direction: column;
    gap: min(max(60px, calc(3.75rem + ((1vw - 10.24px) * 4.4643))), 100px);
    @media(max-width:867px) {
        display: none;
    }
    &__title {
        text-align: center;
        font-size: min(max(24px, calc(1.5rem + ((1vw - 10.24px) * 1.7857))), 40px);
        color: #000000;

        &__price {
            color: green;
            font-size: min(max(20px, calc(1.25rem + ((1vw - 7.68px) * 1.0417))), 32px);
        }
    }

    &__block {
        display: flex;
        flex-direction: column;
        box-shadow: 10px 10px 10px #eee;

        &__top {
            display: flex;
            font-size: min(max(16px, calc(1rem + ((1vw - 10.24px) * 1.5625))), 30px);
            justify-content: space-between;
            color: white;
            div {
                height: min(max(45px, calc(2.8125rem + ((1vw - 7.68px) * 3.0382))), 80px);
                text-align: center;
                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: column;

                &:first-child {
                    background-color: #686868;
                    flex-grow: 1;
                }

                &:last-child {
                    background-color: #333;
                    width: 50%;

                }
            }
        }

        &__bot {
            display: flex;
            flex-direction: column;
            color: #282828;

            &__in {
                background-color: #efefef;
                border-bottom: 1px solid #fff;
                padding: 5px 70px;

                &__top {
                    display: flex;
                    align-items: center;
                    text-align: center;
                    justify-content: space-between;
                    font-size: min(max(12px, calc(0.75rem + ((1vw - 10.24px) * 0.8929))), 20px);
                    font-weight: 700;
                    color: #282828;

                    &__name {
                        width: 100%;
                        text-align: center;
                    }
                }
            }
        }
    }
}

.selected-img {
    display: flex;
    gap: min(max(15px, calc(0.9375rem + ((1vw - 10.24px) * 3.9063))), 50px);
    align-items: center;
    justify-content: center;
    padding-top: min(max(30px, calc(1.875rem + ((1vw - 10.24px) * 4.4643))), 70px);

    @media(max-width:867px) {
        gap: 10px;
        padding-top: 30px;
    }

    img {
        border-radius: 50%;
        width: min(max(40px, calc(2.5rem + ((1vw - 10.24px) * 3.3482))), 70px);
        ;
        height: min(max(40px, calc(2.5rem + ((1vw - 10.24px) * 3.3482))), 70px);
        ;

        @media(max-width:867px) {
            width: 100%;
            height: 100%;
        }
    }
}

.selected-car {
    padding-top: 14%;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
        width: 55%
    }

    @media(max-width:867px) {
        padding-top: 270px;

        img {
            width: 80%;
        }
    }
}

.main {
    padding-top: 100px;
    display: flex;
    flex-direction: column;
    gap: 50px;
    @media(max-width:867px) {
        padding-top: 50px;
    }
    &__title {
        text-align: center;
        font-size: min(max(20px, calc(1.25rem + ((1vw - 10.24px) * 2.2321))), 40px);
        color: #000000;

        @media(max-width:867px) {
            font-size: 32px;
        }
    }

    &__block1 {
        display: flex;
        padding: 0px 20px;
        flex-wrap: wrap;
        align-items: stretch;
        justify-content: space-between;
        width: 100%;
        gap: 2%;
        @media(max-width:1024px){
            flex-direction: column;
        }
        &__card {
            flex: 1;
            width: 32%;
            display: flex;
            flex-direction: column;
            @media(max-width:867px) {
                width: 100%;
            }
            img {
                width: 100%;
                aspect-ratio: 1.24;
                object-fit: cover;
                @media(max-width:867px) {
                    height: min(max(200px, calc(12.5rem + ((1vw - 2.5px) * 51.6796))), 600px);
                }
            }

            &__bot {
                display: flex;
                flex-direction: column;
                gap: 40px;
                padding: 40px;
                background: #e0e0e0;
                flex-grow: 1;
                @media(max-width:768px){
                    padding: 20px;
                    gap: 20px;
                }
                &__title {
                    color: #2e2e2e;
                    font-size: 1vw;
                    font-weight: 700;

                    @media(max-width:867px) {
                        font-size: 18px;
                    }
                }

                &__subtitle {
                    color: #646464;
                    font-size: 1vw;
                    font-weight: 500;

                    @media(max-width:867px) {
                        font-size: 12px;
                    }
                }
            }
        }
    }

    &__block2 {
        display: flex;
        padding: 0px 20px;
        flex-wrap: wrap;
        gap: 2%;
        align-items: stretch;
        @media(max-width:1024px){
            flex-direction: column;
        }
        &__card {
            width: 32%;
            display: flex;
            flex-direction: column;
            @media(max-width:1024px) {
                width: 100%;
            }

            img {
                width: 100%;
                aspect-ratio: 1.24;
                object-fit: cover;
                @media(max-width:1024px) {
                width: 100%;
            }
            }

            &__bot {
                display: flex;
                flex-direction: column;
                gap: 40px;
                padding: 40px;
                background: #e0e0e0;
                height: fit-content;
                flex-grow: 1;

                &__title {
                    color: #2e2e2e;
                    font-size: 1vw;
                    font-weight: 700;

                    @media(max-width:867px) {
                        font-size: 18px;
                    }
                }

                &__subtitle {
                    color: #646464;
                    font-size: 1vw;
                    font-weight: 500;

                    @media(max-width:867px) {
                        font-size: 14px;
                    }
                }
            }
        }
    }
}

.cars {
    background-image: url('@/assets/newImages/carsback.webp');
    background-size: 100% 100%;
    height: fit-content;

    @media(max-width:867px) {
        background-image: url('@/assets/newImages/carsbackmobile.webp');
        background-size: 100% 100%;
    }
}

.video-background {
    position: relative;
    width: 100%;
    height: 100vh;
    /* Настройте высоту видео под ваши нужды */
    overflow: hidden;

    @media(max-width:867px) {
        height: 30vh;
    }
}

.custom-text {
    position: absolute;
    bottom: 50px;
    left: 50px;
    font-size: min(max(40px, calc(2.5rem + ((1vw - 10.24px) * 4.4643))), 80px);
    color: white;
    @media(max-width:867px) {
        left: 2%;
        bottom: 2%;
        font-size: 30px;
    }

}

video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    /* Обрезает видео, чтобы оно занимало весь контейнер */
}

.custom-button {
    position: absolute;
    bottom: 60px;
    right: 60px;
    padding: min(max(12px, calc(0.75rem + ((1vw - 10.24px) * 0.8929))), 20px) min(max(32px, calc(2rem + ((1vw - 10.24px) * 3.125))), 60px);
    background-color: #42373c;
    color: #fff;
    border: none;
    border-radius: 40px;
    cursor: pointer;
    @media(max-width:867px) {
        right: 2%;
        bottom: 4%;
        font-size: 30px;
        padding: 10px;
        font-size: 15px;
    }
}</style>
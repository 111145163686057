<template>
    <div class="dilers">
        <div class="dilers__title">
            {{ $t('diler_1') }}
        </div>
        <div class="dilers__selectBack">
            <div class="dilers__select">
                <div class="dilers__select__top" @click="openSelect = !openSelect">
                    <p>{{ selectedDealer?.name }}</p>
                    <img src="@/assets/newImages/arrow.svg" :class="{customSelect: openSelect == true}"/>
                </div>
                <div class="dilers__select__bot" v-if="openSelect">
                    <div class="dilers__select__bot__in" v-for="item in dealer?.addresses" :key="item" @click="selectDealer(item)">
                        <p>{{ item.name }}</p>
                        <div class="line"></div>
                    </div>
                </div>
            </div>
        </div>
        <div class="dilers__block">
            <div class="dilers__block__left">
                <div id="dilers-map-container" style="height:100%; width: 100%;"></div>
                <div class="infoModal">
                    <p class="infoModal__name">{{ selectedDealer?.name }}</p>
                    <div class="infoModal__address">
                        <img src="@/assets/newImages/address.svg"/>
                        <p>{{ selectedDealer?.address }}</p>
                    </div>
                    <div class="infoModal__address" v-for="item in selectedDealer?.worktime" :key="item">
                        <img src="@/assets/newImages/clock.svg"/>
                        <p>{{ item }}</p>
                    </div>
                    <div class="infoModal__address" v-for="item in selectedDealer?.phone" :key="item">
                        <img src="@/assets/newImages/phone.svg"/>
                        <p>{{ item }}</p>
                    </div>
                    <div class="infoModal__icons">
                        <img src="@/assets/newImages/inst.svg"/>
                        <img src="@/assets/newImages/wpp.svg"/>
                    </div>
                    <button>Сайт дилера</button>
                </div>
            </div>
            <div class="dilers__block__right">
                <img src="@/assets/newImages/dealer.png"/>
            </div>
        </div>
        <!-- <div class="dilers__info">
            <div class="dilers__info__left">
                <div class="dilers__info__left__1"  v-for="item in dealer.addresses" :key="item.id" @click="changeCity(item.city), saveaddress(item.coordinates)" :class="{ 'selected': selectedCity === item.city }">
                    <p class="dilers__info__left__1__title">{{ item.name }}</p>
                    <div class="line"></div>
                    <div class="dilers__info__left__1__input">
                        <p class="dilers__info__left__1__input__title"> {{ $t('diler_2') }}</p>
                        <p class="dilers__info__left__1__input__subtitle">{{item.address}}</p>
                    </div>
                    <div class="dilers__info__left__1__input">
                        <p class="dilers__info__left__1__input__title"> {{ $t('diler_3') }}</p>
                        <div class="dilers__info__left__1__input__subtitle">
                            <div  v-for="time in item.worktime" :key="time">
                                {{ time }}
                            </div>
                        </div>
                    </div>
                    <div class="dilers__info__left__1__input">
                        <p class="dilers__info__left__1__input__title">ТЕЛ: </p>
                        <p class="dilers__info__left__1__input__subtitle" v-for="phone in item.phone" :key="phone">{{ phone }}</p>
                    </div>
                </div>
            </div>
            <div class="dilers__info__right" style="position: relative;">
                <div id="dilers-map-container" style="height:100%; width: 100%;"></div>
            </div>
        </div> -->
        <div class="banner" v-if="dealer">
            <img :src="dealer.banner.image"/>
            <div class="banner__text">
                <p class="banner__text__title">{{dealer.banner.title}}</p>
                <p class="banner__text__sub">Чтобы стать дилером Hongqi напишите по адресу info.hongqi.ast@allur.kz.<br/>Или позвоните по номеру 5599.</p>
            </div>
        </div>
        </div>
</template>

<script>
import { useHead } from "@vueuse/head"
export default {
    setup() {
        useHead({
            title: "Дилеры",
            meta: [
                { hid: 'description', name: 'description', content: 'Описание страницы' },
                { hid: 'canonical', rel: 'canonical', href: 'https://hongqi-auto.kz/dealers'},
            ],
        })
    },
    data() {
        return {
            map: null,
            geocoder: null,
            marker: null,
            selectedCity: '',
            selectedDealer: '',
            dealer: false,
            x: '',
            openSelect: false,
            y: ''
        };
    },
    async created() {
        this.getPage();
    },
    mounted() {
        this.load2GISAPI().then(() => {
            this.initMap();
        });
    },
    methods: {
        async getPage() {
            await this.$axios
                .get(`dealer-addresses?url=hongqi-auto&lang=ru`)
                .then((res) => {
                    this.dealer = res.data;
                    this.selectedDealer = res.data.addresses[0];
                    this.x = res.data.addresses[0].coordinates.x;
                    this.y = res.data.addresses[0].coordinates.y;
                    setTimeout(() => {
                        this.setMarker(res.data.addresses[0].coordinates)
                    }, 500);
                })
                .catch((err) => {});
        },
        load2GISAPI() {
            return new Promise((resolve) => {
                const script = document.createElement('script');
                script.src = 'https://maps.api.2gis.ru/2.0/loader.js?pkg=full';
                script.onload = resolve;
                document.head.appendChild(script);
            });
        },
        initMap() {
            const mapContainer = document.getElementById('dilers-map-container');
            DG.then(() => {
                this.map = DG.map(mapContainer, {
                    center: [this.x, this.y],
                    zoom: 16,
                });
                this.geocoder = DG.geocoding();
            });
        },
        setMarker(value) {
            let coordinates = [value.x, value.y];
            if (this.map) {
                if (this.marker) {
                    this.marker.removeFrom(this.map);
                }

                // Создаем кастомную иконку
                const customIcon = DG.icon({
                    iconUrl: require('@/assets/newImages/marker.png'), // Укажите путь к вашему изображению
                    iconSize: [50, 50], // Размер иконки
                    iconAnchor: [19, 50], // Точка привязки (центр снизу)
                });

                // Добавляем маркер с кастомной иконкой
                this.marker = DG.marker(coordinates, { icon: customIcon }).addTo(this.map);

                this.map.setView(coordinates, 16); // Перемещаем карту к координатам
            }
        },
        changeCity(city) {
            this.selectedCity = city;
        },
        saveaddress(value) {
            this.setMarker(value);
            this.x = value.x;
            this.y = value.y;
        },
        selectDealer(item){
            this.saveaddress(item.coordinates)
            this.selectedDealer = item;
            this.openSelect = false;
        }
    },
};
</script>
<style lang="scss" scoped>
.selected {
  border: 4px solid red !important;
}
.line{
    border-top: 1px solid black;
}
.customSelect{
    rotate: 180deg;
}
.banner{
    position: relative;
    width: 100%;
    aspect-ratio: 3.8;
    padding: 20px min(max(40px, calc(2.5rem + ((1vw - 7.68px) * 3.4722))), 80px);
    background-color: #f3f3f3;
    img{
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
&__text{
    position: absolute;
    color: white;
    display: flex;
    flex-direction: column;
    gap: 20px;
    font-weight: 700;
    top: min(max(25px, calc(1.5625rem + ((1vw - 7.68px) * 2.1701))), 50px) ;
    left: min(max(65px, calc(4.0625rem + ((1vw - 7.68px) * 4.7743))), 120px);
    &__title{
        font-size: min(max(18px, calc(1.125rem + ((1vw - 2.5px) * 3.2335))), 72px);
    }
    &__sub{
        font-size: min(max(14px, calc(0.875rem + ((1vw - 7.68px) * 0.5208))), 20px);
    }
}
}
.dilers{
    display: flex;
    flex-direction: column;
    padding-top: 30px;
    &__selectBack{
        width: 100%;
        background-color: #f3f3f3;
        margin: 0 auto;
        padding: 30px 0px;
    }
    &__select{
        box-shadow: 0px 2px 4px 0px hsba(0, 0%, 0%, 0.15);
        margin: 0 auto;
        width: fit-content;
        background-color: white;
        padding: 15px 0px;
        width: 15%;
        position: relative;
        &__top{
            display: flex;
            gap: 10px;
            align-items: center;
            cursor: pointer;
            justify-content: center;
        }
        &__bot{
            left: 0%;
            padding: 10px 20px;
            display: flex;
            width: 100%;
            flex-direction: column;
            gap: 10px;
            background-color: white;
            position: absolute;
            top: 100%;
            z-index: 98;
            &__in{
                display: flex;
                flex-direction: column;
                gap: 10px;
                cursor: pointer;
                :last-child{
                    .line{
                        display: none !important;
                    }
                }
            }
        }
    }
    &__block{
        display: flex;
        justify-content: space-between;
        width: 100%;
        padding: 0px min(max(40px, calc(2.5rem + ((1vw - 7.68px) * 3.4722))), 80px);
        align-items: stretch;
        background-color: #f3f3f3;
        &__left{
            width: 49%;
            height: auto;
            position: relative;
            .infoModal{
                position: absolute;
                background-color: #f3f3f3;
                z-index: 99;
                top: 20px;
                left: 20px;
                padding: 50px;
                display: flex;
                flex-direction: column;
                gap: 15px;
                &__name{
                    font-size: 24px;
                }
                &__address{
                    display: flex;
                    gap: 12px;
                    align-items: center;
                    img{
                        width: 15px;
                    }
                }
                &__icons{
                    display: flex;
                    gap: 15px;
                    align-items: center;
                    img{
                        cursor: pointer;
                    }
                }
                button{
                    width: 75%;
                    font-size: 12px;
                    padding: 12px 0px;
                    background-color: #42373C;
                    border: none;
                    color: white;
                    cursor: pointer;
                }
            }
        }
        &__right{
            width: 49%;
            height: 100%;
            img{
                width: 100%;
                aspect-ratio: 1.6566;
                object-fit: cover;
                display: flex;
            }
        }
    }
    &__title{
        text-align: center;
        font-size: min(max(20px, calc(1.25rem + ((1vw - 7.68px) * 1.0417))), 32px);
        color: #282828;
        padding-bottom: 30px;
        gap: 10px;
        text-transform: uppercase;
        @media(max-width:867px){
                font-size: 24px;
                padding-bottom: 20px;
            }
    }
    // &__info{
    //     display: flex;
    //     padding: 10px min(max(40px, calc(2.5rem + ((1vw - 7.68px) * 3.4722))), 80px);
    //     justify-content: space-between;
    //     background: #f3f3f3;
    //     @media(max-width:867px){
    //         padding: 30px;
    //     }
    //     &__left{
    //         display: flex;
    //         flex-direction: column;
    //         gap: min(max(18px, calc(1.125rem + ((1vw - 7.68px) * 1.0417))), 30px);
    //         width: 40%;
    //         overflow-y: auto;
    //         max-height: min(max(380px, calc(23.75rem + ((1vw - 7.68px) * 14.7569))), 550px);
    //         @media(max-width:867px){
    //             width: 100%;
    //         }
    //         &__1{
    //             display: flex;
    //             flex-direction: column;
    //             border: 4px solid white;
    //             gap: min(max(12px, calc(0.75rem + ((1vw - 7.68px) * 0.6944))), 20px);
    //             padding: min(max(12px, calc(0.75rem + ((1vw - 7.68px) * 0.6944))), 20px) min(max(16px, calc(1rem + ((1vw - 7.68px) * 1.3889))), 32px);
    //             color: #282828;
    //             background: white;
    //             font-size: min(max(18px, calc(1.125rem + ((1vw - 7.68px) * 1.0417))), 30px);
    //             cursor: pointer;
    //             &__title{
    //                 @media(max-width:867px){
    //                     font-size: 20px;
    //                 }
    //             }
    //             &__input{
    //                 display: flex;
    //                 gap: 5px;
    //                 align-items: center;
    //                 &__title{
    //                     font-size: min(max(12px, calc(0.75rem + ((1vw - 7.68px) * 0.6944))), 20px);
    //                     font-weight: 600;
    //                     @media(max-width:867px){
    //                         font-size: 12px;
    //                         width: 40%;
    //                     }
    //                 }
    //                 &__subtitle{
    //                     font-size: min(max(12px, calc(0.75rem + ((1vw - 7.68px) * 0.5208))), 18px);
    //                     @media(max-width:867px){
    //                         font-size: 10px;
    //                         width: 55%;
    //                     }
    //                 }
    //             }
    //         }
    //         &__2{
    //             display: flex;
    //             flex-direction: column;
    //             border: 4px solid white;
    //             gap: min(max(12px, calc(0.75rem + ((1vw - 7.68px) * 0.6944))), 20px);
    //             padding: min(max(12px, calc(0.75rem + ((1vw - 7.68px) * 0.6944))), 20px) min(max(16px, calc(1rem + ((1vw - 7.68px) * 1.3889))), 32px);
    //             color: #282828;
    //             background: white;
    //             font-size: min(max(18px, calc(1.125rem + ((1vw - 7.68px) * 1.0417))), 30px);
    //             cursor: pointer;
    //             &__title{
    //                 @media(max-width:867px){
    //                     font-size: 20px;
    //                 }
    //             }
    //         }
    //     }
    //     &__right{
    //         width: 60%;
    //         @media(max-width:867px){
    //             display: none;
    //         }
    //     }
    // }
}
</style>
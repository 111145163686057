<template>
  <CallBackModal v-if="$store.state.openCallBack == true" />
  <GoodJobView v-if="$store.state.openOk == true" />
  <BurgerView v-if="$store.state.openBurger == true" />
  <div class="desctop sticky">
    <div class="headerBackground">
      <div class="header container">
        <div class="header__left">
          <router-link to="/"
            ><img src="@/assets/icons/Allur_HONGQI2.png"
          /></router-link>
        </div>
        <div class="header__right">
          <div class="header__right__models" 
          @mouseenter="showHeaderModels" 
          @mouseleave="hideHeaderModels">
            <router-link
              to="/model"
              style="text-decoration: none; color: #808080"
              ><p class="titleitems">{{ $t('model') }}</p></router-link
            >
            <div class="headermodels" :class="{ 'headermodels--visible': isVisible }">
                <div
                  class="headermodels__car"
                  @click="$router.push('/models/' + header[0]?.models[0]?.slug)"
                >
                  <p>{{ header[0]?.models[0]?.title }}</p>
                  <img :src="header[0]?.models[0]?.logo" />
                  <p class="headermodels__car__price">от {{ header[0]?.models[0]?.price.toLocaleString() }} ₸</p>
                </div>
                <div
                  class="headermodels__car"
                  @click="$router.push('/models/' + header[0]?.models[1]?.slug)"
                >
                  <p>{{ header[0]?.models[1]?.title }}</p>
                  <img :src="header[0]?.models[1]?.logo" />
                  <p class="headermodels__car__price">от {{ header[0]?.models[1]?.price.toLocaleString() }} ₸</p>
                </div>
                <div
                  class="headermodels__car"
                  @click="$router.push('/models/' + header[1]?.models[0]?.slug)"
                >
                  <p>{{ header[1]?.models[0]?.title }}</p>
                  <img :src="header[1]?.models[0]?.logo" />
                  <p class="headermodels__car__price">от {{ header[1]?.models[0]?.price.toLocaleString() }} ₸</p>
                </div>
                <div
                  class="headermodels__car"
                  @click="$router.push('/models/' + header[2]?.models[0]?.slug)"
                >
                  <p>{{ header[2]?.models[0]?.title }}</p>
                  <img :src="header[2]?.models[0]?.logo" />
                  <p class="headermodels__car__price">от {{ header[2]?.models[0]?.price.toLocaleString() }} ₸</p>
                </div>
            </div>
          </div>
          <div class="header__right__dillers">
            <router-link
              to="/dealers"
              style="text-decoration: none; color: #808080"
              ><p class="titleitems">{{ $t('head_1') }}</p></router-link
            >
          </div>
          <div class="header__right__news">
            <router-link
              to="/news"
              style="text-decoration: none; color: #808080"
              ><p class="titleitems">{{ $t('head_2') }}</p></router-link
            >
          </div>
          <div class="header__right__news">
            <router-link
              to="/owner"
              style="text-decoration: none; color: #808080"
              ><p class="titleitems">{{ $t('owner') }}</p></router-link
            >
          </div>
          <div class="header__right__world">
            <router-link
              to="/brand"
              style="text-decoration: none; color: #808080"
              ><p class="titleitems">{{ $t('head_3') }}</p></router-link
            >
          </div>
          <div class="header__right__library">
            <router-link
              to="/test-drive"
              style="text-decoration: none; color: #808080"
              ><p class="titleitems">{{ $t('head_9') }}</p></router-link
            >
          </div>
          <div class="header__right__lang">
            <img src="@/assets/icons/lang.svg"/>
            <Select
              :data="data1"
              placeholderCustom="Ru"
              v-model="value1"
              @vModelCustom="selectThisValueParent"
            />
          </div>
          <div class="header__right__call" itemscope itemtype="http://schema.org/Organization">
            <img src="@/assets/icons/call5588.svg" alt="Телефон"/>
            <p itemprop="telephone">5599</p>
            <meta itemprop="name" content="Hongqi">
            <meta itemprop="email" content="Info.hongqi.ast@allur.kz" style="display:none;">
            <meta itemprop="address" content="Адрес: Мангилик Ел, 63а" style="display:none;">
            <meta itemprop="branchCode" content="230340001878">
            <meta itemprop="postalCode" content="020000">
            <meta itemprop="addressLocality" content="г. Астана">
            <meta itemprop="addressRegion" content="Казахстан">
          </div>
        </div>
      </div>
    </div>
  </div>
  <img
    src="@/assets/icons/callback.svg"
    style="width: 65px; height:65px;"
    class="callback"
    @click="$store.state.openCallBack = !$store.state.openCallBack"
  />
  <div class="mobile sticky">
    <div class="headermobile">
      <div class="headermobile__left">
        <router-link to="/"
          ><img src="@/assets/icons/Allur_HONGQI.png"
        /></router-link>
      </div>
      <div class="headermobile__right">
        <div class="headermobile__right__language">
          <img src="@/assets/icons/lang.svg" />
          <Select
              :data="data1"
              placeholderCustom="Ru"
              v-model="value1"
              @vModelCustom="selectThisValueParent"
            />
        </div>
        <div class="headermobile__right__burger">
          <img
            src="@/assets/icons/menu.svg"
            @click="$store.state.openBurger = true"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Select from "@/components/UI/Select.vue";
import { mapMutations } from 'vuex';
import CallBackModal from "../CallBackModal.vue";
import GoodJobView from "@/components/GoodJobView.vue";
import BurgerView from "@/components/BurgerView.vue";
export default {
  components: { Select, CallBackModal, GoodJobView, BurgerView },
  data() {
    return {
      value1: 1,
      isVisible: false,
      header: [],
      data1: [
        {
          value: 1,
          label: "RU",
          store: {
            id: 1,
            val: "ru"
          }
        },
        {
          value: 2,
          label: "KZ",
          store: {
            id: 1,
            val: "kz"
          }
        },
      ],
      shouldHide: false,
      currentModel: 1,
    };
  },
  async created() {
    await this.$axios
      .get(`/header`)
      .then((res) => {
        this.header = res.data[0];
      })
      .catch((err) => {});
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
  },
  beforeUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  methods: {
    ...mapMutations(["SET_LANGUAGE"]), 
        getLang(option) { 
          this.$i18n.locale = option; 
          this.SET_LANGUAGE(option); 
          this.$forceUpdate(); 
          location.reload(); 
        },
    handleScroll() {
      this.shouldHide = window.scrollY > 0;
    },
    showModel(value) {
      this.currentModel = value;
    },
    selectThisValueParent(value) {
      this.value1 = value;
    },
    showHeaderModels() {
      this.isVisible = true;
    },
    hideHeaderModels() {
      this.isVisible = false;
    },
  },
  watch: {
    $route() {
      this.$store.state.openBurger = false;
    },
    '$store.state.openBurger'() {
      if (this.$store.state.openBurger) {
        document.documentElement.style.overflowY = "hidden"
      } else {
        document.documentElement.removeAttribute("style");
      }
    }
  },
};
</script>
<style lang="scss" scoped>
.callback {
  position: fixed;
  bottom: 5%;
  right: 3%;
  z-index: 1000;
  &:hover {
    transition: all 0.5s ease;
    transform: scale(1.2);
  }
}
.active {
  font-weight: 700;
  color: black;
}
.custom-select {
  @media (max-width: 1400px) {
    font-size: 15px;
  }
}
.titleitems {
  &:hover {
    p {
      color: black;
    }
  }
}
.headermobile {
  background-color: black;
  box-sizing: border-box;
  padding: 0px 15px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 75px;
  &__left {
    img {
      width: 150px;
      aspect-ratio: 3.33;
      object-fit: cover;
    }
  }
  &__right {
    display: flex;
    gap: 27px;
    align-items: center;
    img {
      filter: brightness(0) saturate(100%) invert(100%) sepia(0%)
        saturate(6868%) hue-rotate(55deg) brightness(125%) contrast(95%);
    }
    &__language {
      display: flex;
      gap: 5px;
      align-items: center;
      .mobileselect {
        border: none;
        background: inherit;
        color: white;
      }
    }
  }
}
.headermodels {
  position: fixed;
  height: min(max(250px, calc(15.625rem + ((1vw - 10.24px) * 11.1607))), 350px);
  background: #f3f3f3;
  width: 100%;
  padding: min(max(30px, calc(1.875rem + ((1vw - 10.24px) * 2.2321))), 50px);
  margin-top: min(max(24px, calc(1.5rem + ((1vw - 10.24px) * 2.6786))), 48px);
  left: 0;
  display: flex;
  justify-content: space-around;
  align-items: center;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  opacity: 0;
  transform: translateY(-10px);
  visibility: hidden;
  transition: opacity 0.5s ease, transform 0.5s ease, visibility 0.5s ease;
  &:hover, .headermodels--visible {
    opacity: 1;
    transform: translateY(0);
    visibility: visible;
  }
  &__car {
    display: flex;
    cursor: pointer;
    flex-direction: column;
    align-items: center;
    color: #aaa7a7;
    font-size: min(max(32px, calc(2rem + ((1vw - 7.68px) * 1.9097))), 54px);
    width: 18%;
    &__price{
      font-size: min(max(12px, calc(0.75rem + ((1vw - 7.68px) * 0.3472))), 16px);
    }
    img {
      width: 100%;
      aspect-ratio: 43/22;
      object-fit: cover;
    }
  }
}
.sticky {
  position: sticky;
  top: 0;
  z-index: 998;
}
.headerBackground {
  background: #f3f3f3;
  width: 100%;
  box-sizing: border-box;
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-sizing: border-box;
    padding: 0px;
    &__right {
      display: flex;
      width: 80%;
      color: #666666;
      align-items: center;
      font-weight: 700;
      justify-content: space-between;
      font-size: min(max(10px, calc(0.625rem + ((1vw - 7.68px) * 0.5208))), 16px);
      text-transform: uppercase;
      &__dillers {
        padding: min(max(24px, calc(1.5rem + ((1vw - 10.24px) * 2.6786))), 48px) 0px;
        display: flex;
        justify-content: center;
      }
      &__library {
        padding: min(max(24px, calc(1.5rem + ((1vw - 10.24px) * 2.6786))), 48px) 0px;
        display: flex;
        justify-content: center;
      }
      &__news {
        padding: min(max(24px, calc(1.5rem + ((1vw - 10.24px) * 2.6786))), 48px) 0px;
        display: flex;
        justify-content: center;
      }
      &__world {
        padding: min(max(24px, calc(1.5rem + ((1vw - 10.24px) * 2.6786))), 48px) 0px;
        display: flex;
        justify-content: center;
      }
      &__models {
        padding: min(max(24px, calc(1.5rem + ((1vw - 10.24px) * 2.6786))), 48px) 30px;
        display: flex;
        justify-content: center;
        &:hover {
          .headermodels {
            opacity: 1;
            transform: translateY(0);
            transition: all 0.7s ease;
            transition-delay: 0s;
            visibility: visible;
          }
        }
      }
      &__lang {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 5px;
        img{
          width: min(max(15px, calc(0.9375rem + ((1vw - 7.68px) * 0.8681))), 25px);
          height: min(max(15px, calc(0.9375rem + ((1vw - 7.68px) * 0.8681))), 25px);
        }
        select {
          font-size: min(max(10px, calc(0.625rem + ((1vw - 7.68px) * 0.6944))), 18px);
          background: inherit;
          outline: none;
        }
      }
      &__call {
        display: flex;
        gap: 5px;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        img{
          width: min(max(15px, calc(0.9375rem + ((1vw - 7.68px) * 0.8681))), 25px);
          height: min(max(15px, calc(0.9375rem + ((1vw - 7.68px) * 0.8681))), 25px);
        }
      }
    }
    &__left {
    width: 15%;
    aspect-ratio: 3.33;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }
}
</style>
<template>
    <div class="brand">
        <div class="brand__banner">
            <p>Hongqi</p>
        </div>
        <div class="brand__conceptionBack">
            <div class="brand__conception container">
                <p class="title">Бренд</p>
                <div class="brand__conception__block">
                    <div class="brand__conception__block__left">
                        <p class="title">КОНЦЕПЦИЯ</p>
                        <p class="brand__conception__block__left__text">Концепция бренда Hongqi подчеркивает «новое благородство», «новую изысканность» и «новые чувства», подразумевая глубокую интеграцию традиционной восточной и мировой культуры, сочетая передовую науку и технологии с эмоциями для предоставления услуг превосходного качества.</p>
                    </div>
                    <div class="brand__conception__block__right">
                        <img src="@/assets/newImages/conception.png"/>
                    </div>
                </div>
            </div>
        </div>
        <div class="brand__year">
            <p class="title">Бренд с богатым наследием</p>
            <swiper 
                class="brand__year__swiper"
                :slidesPerView="1.7" 
                :spaceBetween="90"
                :lazy="true" 
                @swiper="setThumbsSwiper" 
                :thumbs="{ swiper: thumbsSwiper }" 
                :modules="modules"
                :speed="400"
            > 
                <swiper-slide v-for="(item, index) in yearData" :key="item" class="yearCard"> 
                    <div class="yearCard__left">
                        <img src="@/assets/newImages/year.png"/>
                    </div>
                    <div class="yearCard__right">
                        <p class="yearCard__right__title">{{ item.year }}</p>
                        <p class="yearCard__right__text">{{ item.text }}</p>
                    </div>
                </swiper-slide> 
            </swiper>
            <swiper 
                class="brand__year__miniSwiper"
                :slidesPerView="4" 
                :spaceBetween="50"
                :scrollbar="{
                    draggable: true
                }"
                :lazy="true" 
                @swiper="setThumbsSwiper" 
                @slideChange="setThumbsSwiper"
                :modules="modules"
                :speed="550"
            > 
                <swiper-slide v-for="(item, index) in yearData" :key="item" class="miniCard"> 
                    <p>{{ item.year }}</p>
                </swiper-slide> 
            </swiper>
        </div>
    </div>
</template>
<script>
import { Swiper, SwiperSlide,} from "swiper/vue"; 
import "swiper/css"; 
import "swiper/css/pagination"; 
import "swiper/css/navigation";  
import "swiper/css/scrollbar";
import { useHead } from "@vueuse/head"
import SwiperCore, { A11y, Autoplay } from "swiper"; 
import { Navigation, Pagination, Scrollbar } from 'swiper'; 
import { Thumbs, FreeMode, EffectFade } from "swiper";
import "swiper/swiper.min.css"; 
SwiperCore.use([Navigation,Pagination, A11y, Autoplay, Scrollbar])
export default {
    data(){
        return{
            modules: [Thumbs, FreeMode, EffectFade],
            thumbsSwiper: null,
            yearData: [
                {
                    year: '1953',
                    text: 'Основание концерна FAW — крупнейшего автопроизводителя КНР',
                },
                {
                    year: '1956',
                    text: 'Основание концерна FAW — крупнейшего автопроизводителя КНР',
                },
                {
                    year: '1978',
                    text: 'Основание концерна FAW — крупнейшего автопроизводителя КНР',
                },
                {
                    year: '1999',
                    text: 'Основание концерна FAW — крупнейшего автопроизводителя КНР',
                },
                {
                    year: '2007',
                    text: 'Основание концерна FAW — крупнейшего автопроизводителя КНР',
                },
                {
                    year: '2014',
                    text: 'Основание концерна FAW — крупнейшего автопроизводителя КНР',
                },
                {
                    year: '2017',
                    text: 'Основание концерна FAW — крупнейшего автопроизводителя КНР',
                },
                {
                    year: '2022',
                    text: 'Основание концерна FAW — крупнейшего автопроизводителя КНР',
                },
                {
                    year: '2024',
                    text: 'Основание концерна FAW — крупнейшего автопроизводителя КНР',
                },
            ]
        }
    },
    components: { Swiper, SwiperSlide },
    methods:{
        setThumbsSwiper(swiper) {
            this.thumbsSwiper = swiper;
        },
    }
}
</script>
<style lang="scss" scoped>
.title{
    font-size: min(max(24px, calc(1.5rem + ((1vw - 7.68px) * 1.3889))), 40px);
}
    .brand{
        &__banner{
            width: 100%;
            aspect-ratio: 2.382;
            background-position: center;
            background-size: cover;
            background-image: url('@/assets/newImages/brandBanner.png');
        }
        &__conceptionBack{
            background-color: #F3F3F3;
        }
        &__conception{
            padding: min(max(25px, calc(1.5625rem + ((1vw - 7.68px) * 2.1701))), 50px) 0px;
            display: flex;
            flex-direction: column;
            gap: min(max(25px, calc(1.5625rem + ((1vw - 7.68px) * 2.1701))), 50px);
            align-items: center;
            &__block{
                display: flex;
                justify-content: space-between;
                align-items: stretch;
                &__left{
                    width: 50%;
                    background-color: white;
                    padding: min(max(20px, calc(1.25rem + ((1vw - 7.68px) * 1.7361))), 40px);
                    display: flex;
                    flex-direction: column;
                    gap: min(max(25px, calc(1.5625rem + ((1vw - 7.68px) * 2.1701))), 50px);
                    &__text{
                        font-size: min(max(16px, calc(1rem + ((1vw - 7.68px) * 0.6944))), 24px);
                    }
                }
                &__right{
                    width: 50%;
                    img{
                        width: 100%;
                        aspect-ratio: 1.484;
                        object-fit: cover;
                        display: flex;
                    }
                }
            }
        }
        &__year{
            padding: min(max(45px, calc(2.8125rem + ((1vw - 7.68px) * 3.9063))), 90px) 0px;
            display: flex;
            flex-direction: column;
            gap: min(max(50px, calc(3.125rem + ((1vw - 7.68px) * 4.3403))), 100px);
            align-items: center;
            &__swiper{
                width: 100%;
                margin-left: 5%;
                .yearCard{
                    display: flex;
                    justify-content: space-between;
                    cursor: pointer;
                    &__left{
                        width: 60%;
                        img{
                            width: 100%;
                            aspect-ratio: 1.312;
                            object-fit: cover;
                        }
                    }
                    &__right{
                        display: flex;
                        flex-direction: column;
                        gap: min(max(25px, calc(1.5625rem + ((1vw - 7.68px) * 2.1701))), 50px);
                        align-items: center;
                        justify-content: center;
                        text-align: center;
                        padding: 0px 20px;
                        &__title{
                            font-size: min(max(75px, calc(4.6875rem + ((1vw - 7.68px) * 6.5104))), 150px);
                            color: #7a7a7a;
                            font-weight: 700;
                        }
                        &__text{
                            font-size: min(max(14px, calc(0.875rem + ((1vw - 7.68px) * 0.3472))), 18px);
                        }
                    }
                }
            }
            &__miniSwiper{
                width: 50%;
                text-align: center;
                font-size: 24px;
                .miniCard{
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }
                .swiper-slide-thumb-active{
                    font-weight: 700;
                    font-size: 32px;
                }
            }
        }
    }
</style>
<template>
    <div>
        <div class="line"></div>
        <div class="footer">
            <div class="footer__top">
                <div class="footer__top__left">
                    <router-link to="/"><img src="@/assets/newImages/footerlogo.webp"/></router-link>
                </div>
                <div class="footer__top__info">
                    <div class="footer__top__info__1">
                        <p>{{ $t('foot_1') }}</p>
                        <div class="line1"></div>
                    </div>
                    <p class="footer__top__info__2">{{ $t('foot_2') }}</p>
                    <div class="footer__top__info__3">
                        <input v-model="UserData.email" :placeholder="$t('email')" type="text"/>
                        <button @click="sendData">{{ $t('foot_3') }}</button>
                    </div>
                    <div class="footer__top__info__4">
                        <input v-model="UserData.agreement" type="checkbox" class="checkbox"/>
                        <p>{{ $t('foot_4') }}</p>
                    </div>
                </div>
            </div>
            <div class="footer__botbackgound">
                <div class="footer__bot">
                    <div class="footer__bot__1">
                        <router-link to="/allur" style="color: inherit; text-decoration: none;"><p>{{ $t('allur2') }}</p></router-link>
                        <a :href="footer" target="_blank" style="cursor: pointer; color: inherit; text-decoration: none;">{{ $t('foot_6') }}</a>
                        <a :href="agree" target="_blank" style="cursor: pointer; color: inherit; text-decoration: none;">{{ $t('agree') }}</a>
                        <router-link to="/contactus" style="color: inherit; text-decoration: none;"><p>{{ $t('foot_7') }}</p></router-link>
                        <a :href="agree" target="_blank" style="cursor: pointer; color: inherit; text-decoration: none;">{{ $t('agree') }}</a>
                    </div>
                    <p>{{ $t('foot_8') }}</p>
                    <p>{{ $t('foot_9') }}</p>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import axios from 'axios'
export default {
    data:() => ({
        footer: false,
        agree : false,
        UserData:{
            email: null,
            agreement: false,
        }
    }),
    async created() {
        await this.$axios
        .get(`/offer`)
        .then((res) => {
            this.footer = res.data.offer;
        })
        .catch((err) => {});
        await this.$axios
        .get(`/user-agreement?lang=ru`)
        .then((res) => {
            this.agree = res.data.offer;
        })
        .catch((err) => {});
    },
    methods:{
        sendData() {
            const article = {
                Email: this.UserData.email,
                Agreement: this.UserData.agreement,
                Date: Date()
            };
            axios
                .post("https://sheetdb.io/api/v1/098p87j5j1ygf?sheet=Footer", article)
                .then((response) => {
                    if (response.status === 201) {
                        alert('Успешно подписались')
                    } else {
                        console.log(response)
                    }
                })
                .catch((e) => {

                    console.log(e)
                })

        },
        downloadFile() {
            const fileUrl = '/assets/newFile.pdf';// Путь к файлу
            const link = document.createElement('a');
            link.href = fileUrl;
            link.download = 'Публичная_оферта_Конфиденциальность_персональных_данных.pdf'; // Имя файла, которое будет отображаться при скачивании
            link.click();
        }
    }
}
</script>
<style lang="scss" scoped>
.line{
    border: 1.5px solid rgba(122, 111, 111, 0.534);
}
.line1{
    border: 0.5px solid black;
}
.footer{
    display: flex;
    flex-direction: column;
    gap: min(max(42px, calc(2.625rem + ((1vw - 7.68px) * 2.4306))), 70px);
    &__top{
        padding: min(max(32px, calc(2rem + ((1vw - 7.68px) * 1.5625))), 50px) min(max(120px, calc(7.5rem + ((1vw - 7.68px) * 11.2847))), 250px);
        display: flex;
        justify-content: space-between;
        @media(max-width:867px){
                padding: 20px;
        }
        &__left{
            width: min(max(80px, calc(5rem + ((1vw - 7.68px) * 3.4722))), 120px);
            height: min(max(80px, calc(5rem + ((1vw - 7.68px) * 3.4722))), 120px);
            @media(max-width:867px){
                display: none;
            }
            img{
                width: 100%;
                height: 100%;
                object-fit: cover
            }
        }
        &__info{
            width: 80%;
            display: flex;
            flex-direction: column;
            gap: 20px;
            font-size: min(max(10px, calc(0.625rem + ((1vw - 7.68px) * 0.5208))), 16px);
            @media(max-width:867px){
                width: 100%;
            }
            &__1{
                display: flex;
                flex-direction: column;
                gap: 10px;
            }
            &__2{
                @media(max-width:867px){
                    font-size: 10px;
                }
            }
            &__3{
                display: flex;
                gap: min(max(18px, calc(1.125rem + ((1vw - 7.68px) * 1.0417))), 30px);
                padding-top: min(max(16px, calc(1rem + ((1vw - 7.68px) * 1.0417))), 28px);
                @media(max-width:867px){
                      gap: 10px;
                    }
                input{
                    padding: 10px;
                    border-radius: 40px;
                    background-color: #eee;
                    border: 1px solid #b8b8b8;
                    border-radius: 0.9vw;
                    width: 30%;
                    font-size: min(max(10px, calc(0.625rem + ((1vw - 7.68px) * 0.5208))), 16px);
                    outline: none;
                    @media(max-width:867px){
                        padding: 5px 20px;
                        font-size: 12px;
                        border-radius: 50px;
                    }
                }
                button{
                    background-color: #42373c;
                    cursor: pointer;
                    border-radius: 40px;
                    color: #fff;
                    padding: 10px 20px;
                    border: none;
                    transition: all 0.5s ease;
                    @media(max-width:867px){
                        font-size: 12px;
                        padding: 5px 15px;
                    }
                    &:hover{
                        background: red;

                    }
                }
            }
            &__4{
                display: flex;
                gap: 15px;
                align-items: center;
                padding-top: 10px;
                @media(max-width:867px){
                        font-size: 12px;
                    }
                .checkbox{
                    width: 20px;
                    height: 20px;
                    @media(max-width:867px){
                        width: 15px;
                        height: 15px;
                    }
                }
            }
        }
    }
        &__botbackgound{
            background: #636363;
        }
        &__bot{
            color: #FFF;
            padding:  min(max(12px, calc(0.75rem + ((1vw - 7.68px) * 0.6944))), 20px);
            opacity: .86;
            display: flex;
            flex-direction: column;
            font-size: 10px;
            gap:  min(max(12px, calc(0.75rem + ((1vw - 7.68px) * 0.6944))), 20px);

            &__1{
                display: flex;
                gap: 40px;
                justify-content: center;
                font-size:  min(max(12px, calc(0.75rem + ((1vw - 7.68px) * 0.6944))), 20px);
                @media(max-width:867px){
                    flex-direction: column;
                    font-size: 15px;
                    gap: 20px;
                }
            }
            text-align: center;
        }
}
</style>
